<template>
  <div class="feedback-form-status-card" v-if="statusData">
    <div class="card text-center shadow-lg p-4 border-0 feedback-form-card">
      <div class="card-body">
        <div class="mb-1">
          <feather-icon
            :icon="statusData.icon"
            :class="statusData.iconClass"
            size="75"
          />
        </div>
        <h1 class="text-dark fw-700">{{ statusData.title }}</h1>
        <p class="card-text mt-3 fw-700 text-dark">
          {{ statusData.message }}
        </p>
 
      </div>
    </div>
  </div>
</template>

<script>
import { statusCardDetail } from "../../../mixin/peerFeedback/statusCard.js";
export default {
  name: "FeedBackFormStatus",
  mixins: [statusCardDetail],
};
</script>
