import * as moment from "moment/moment";
import debounce from "lodash/debounce";
import { eventBus } from "@/main";

export const projectFeedbackDetail = {
  props: {
    projectDtl: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loader: false,
      isOpen: false,
      user_ids: [],
      totalCount: 0,
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.per_page || 25,
      fields: [
        {
          key: "start_date",
          label: "Feedback Date",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "200px !important",
          },
        },
        {
          key: "created_at",
          label: "Created Date",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "200px !important",
          },
        },
        {
          key: "type",
          label: "Type",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "200px !important",
          },
        },
        {
          key: "user_name",
          label: "Team member",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "350px !important",
          },
        },
        {
          key: "rating",
          label: "Rating",
          tdStyle: { textAlign: "center !important" },
        },
        {
          key: "copyLink",
          label: "Copy Link",
          tdStyle: {
            textAlign: "center !important",
          },
        },
        {
          key: "action",
          label: "Action",
          tdStyle: { textAlign: "center !important" },
        },
      ],
      feedbackLists: [],
      activeBtn: "active",
      dateRangeConfig: {
        mode: "range",
        inline: true,
        maxDate: moment().endOf("week").add(1, "d").format("YYYY-MM-DD"),
      },
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      search: this.$route.query.search || null,
      prData: {},
      btnLoader: false,
      members: [],
      projectFeedbackLink: [],
      type: null,
      form_expire_date: moment().format("YYYY-MM-DD"),
      date: `${moment()
        .startOf("month")
        .format("YYYY-MM-DD")} to ${moment().format("YYYY-MM-DD")}`,
      types: [
        { name: "General", value: "general" },
        { name: "Individual", value: "individual" },
      ],
      expireDate: {
        maxDate: new Date(),
        dateFormat: "Y/m/d",
      },
      startAndEndDate: {
        maxDate: new Date(),
        dateFormat: "Y/m/d",
        mode: "range",
      },
      assigneeList: [],
    };
  },

  computed: {
    filterUserOptions() {
      const users = this.projectDtl.project_user.map((element) => ({
        value: element.id,
        label: element.full_name,
      }));

      // Sort users list by label
      return users;
    },
    statusOptions() {
      return [
        { label: "General", value: "general" },
        { label: "Individual", value: "individual" },
      ];
    },
    debouncedSearch: {
      get() {
        return this.search;
      },
      set: debounce(function (value) {
        this.search = value;
        this.feedbackList();
      }, 500),
    },
  },
  watch: {
    currentPage(nv) {
      if (this.$route.query.currentPage !== nv) {
        this.feedbackList();
      }
    },
    perPage(nv) {
      if (this.$route.query.perPage !== nv) {
        if (this.currentPage == 1) {
          this.feedbackList();
        } else {
          this.currentPage = 1;
        }
      }
    },
  },
  mounted() {
    this.initializeQueryParams();
    this.feedbackList();
  },
  methods: {
    initializeQueryParams() {
      const {
        startDate,
        endDate,
        users,
        per_page: perPage,
        page,
        search,
        type,
      } = this.$route.query;
      this.startDate = startDate ?? this.startDate;
      this.endDate = endDate ?? this.endDate;
      this.user_ids = users
        ? Array.isArray(users)
          ? users
          : [users]
        : this.user_ids;
      this.perPage = Number(perPage) || this.perPage;
      this.currentPage = Number(page) || this.currentPage;
      this.search = search ?? this.search;
      this.type = type ?? this.type;
    },
    updateQueryParams() {
      const query = this.getQueryParams();
      // Push the query parameters to the route
      this.routeQueryUpdate(query);
    },
    getQueryParams() {
      const queryParams = {
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        users: this.user_ids,
      };

      // Update the route with query parameters
      // Filter out null or empty values from the query object
      return Object.keys(queryParams).reduce((acc, key) => {
        if (queryParams[key]) acc[key] = queryParams[key];
        return acc;
      }, {});
    },
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null;
      this.endDate = obj && obj.endDate ? obj.endDate : null;
      this.activeBtn = obj && obj.active ? obj.active : null;
      this.feedbackList();
    },
    clearFilter() {
      this.startDate = moment().startOf("month").format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
      this.search = null;
      this.type = null;
      this.per_page = 25;
      this.user_ids = [];
      this.feedbackList();
    },

    async feedbackList() {
      this.loader = true;
      const input = {
        start_date: this.startDate,
        end_date: this.endDate,
        user_ids: this.user_ids,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        projectId: [this.$route.params.id3],
        status: this.type,
      };
      this.updateQueryParams();
      const response = await this.getHTTPPostResponse(
        "project-feedback/project-feedback-form-list",
        input,
        false
      );

      if (response && response.status === 200) {
        this.feedbackLists = response.data.project_feedbacks;
        this.totalCount = response.data.count;
      }
      setTimeout(() => {
        this.loader = false;
      }, 200);
    },

    /**
     * @emits edit-feedback-request // to pass data & open feedback form
     */
    edit(data) {
      eventBus.$emit("edit-feedback-request", data);
    },
  },
};
