var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"questionObserver"},[_c('b-sidebar',{staticClass:"sidebar-form",attrs:{"id":"feedbackQuestion","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.sidebarToggle},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Add")+" Question ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(_vm._s(_vm.id ? "Edit" : "Add New")+" Question "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:('% this member % replace with Employee First name'
            ),expression:"'% this member % replace with Employee First name'\n            ",modifiers:{"hover":true,"top":true,"html":true}}],ref:"customTooltip",staticClass:"ml-1 text-primary",attrs:{"icon":"HelpCircleIcon","size":"100","aria-label":"Feedback status information"}})],1),_c('div',{staticClass:"line",staticStyle:{"width":"200px"}}),_c('div',{staticClass:"b-sidebar-header"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"label-for":"Question"}},[_vm._v("Question "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Question","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Question","state":errors.length > 0 ? false : null,"placeholder":"Enter Question & % this member % replace with Employee First name","name":"Question"},model:{value:(_vm.question),callback:function ($$v) {_vm.question=$$v},expression:"question"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"label-for":"Description"}},[_vm._v("Description "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Description","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"Description","state":errors.length > 0 ? false : null,"placeholder":"Enter Description","name":"Question"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"work_type"}},[_c('validation-provider',{attrs:{"name":"Type","mode":"passive","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"name":"work_type","label":"name","options":_vm.feedbackTypeList,"reduce":function (option) { return option.value; },"placeholder":"Select question for"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }