<template>
  <div>
    <div class="viewEdit">
      <!--heading-->
      <b-card class="mb-0 timesheet-header mt-1" v-show="false">
        <div class="card-header-edit d-flex justify-content-between">
          <p class="title-pr-">Project Feedback</p>
        </div>
      </b-card>

      <!--filter start-->
      <div class="pr-filter">
        <div class="d-flex">
          <p class="filter-title" style="align-self: center">Filters:</p>

          <custom-dropdown v-model="user_ids" :options="filterUserOptions" multiple label="Assignee" icon="EditIcon" />

          <custom-dropdown v-model="type" :options="statusOptions" label="Type" icon="EditIcon" :search-box="false" />

          <div style="align-self: center">
            <OptionDropdown v-model="perPage" />
          </div>
        </div>
        <div>
          <custom-calender :start="startDate" :end="endDate" :active-btn="activeBtn"
            :date-range-config="dateRangeConfig" :from="'project'" @date="updateDate" />
        </div>
        <div class="d-flex justify-content-center align-item-center gap-12">
          <b-input-group class="input-group-merge" style="
              min-width: 250px;
              max-width: 200px;
              width: 250px;
              height: 33px;
            ">
            <b-form-input v-model="debouncedSearch" placeholder="Search by employee name" style="height: 33px" />

            <b-input-group-append is-text>
              <feather-icon v-if="debouncedSearch" icon="XIcon" @click="(debouncedSearch = null), (search = null)" />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <button class="apply-filter filterRedesign" @click="feedbackList()" style="height: 33px">
            Apply Filter
          </button>
          <button class="apply-filter-clear filterRedesign" @click="clearFilter()" style="height: 33px">
            Clear
          </button>

          <b-button v-b-toggle.addEditRequest class="btn-role btn-secondary p-75 pr-add-request" variant="secondary"
            style="height: 33px">
            Add Feedback
          </b-button>
        </div>
      </div>
      <!--filter end-->

    </div>
    <AddProjectFeedback @reloadList="feedbackList()" :project-users="filterUserOptions" />

    <div class="table role test-plan-table">
      <table-loading v-if="loader" :fields="fields" />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->
          <b-table responsive :items="feedbackLists" :fields="fields" show-empty
            class="scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table pr-table">
            <template #cell(start_date)="data">
              <p class="tableData">
                {{ data.item.start_date | DateDDMMYYYYFormat }} to {{ data.item.end_date | DateDDMMYYYYFormat }}
              </p>
            </template>
            <template #cell(user_name)="data">
              <p class="tableData">
                {{
                data.item.user && data.item.user.full_name ? data.item.user.full_name : '-'
                }}
              </p>
            </template>
            <template #cell(created_at)="data">
              <p class="tableData">
                {{ data.item.created_at | DateDDMMYYYYFormat }}
              </p>
            </template>
            <template #cell(rating)="data">
              <p class="tableData">
                {{
                data.item.rating ? data.item.rating : '-'
                }}
              </p>
            </template>
            <template #cell(copyLink)="data">
              <div class="copy-link" @click="copyToClipboard(data.item.url)">
                <feather-icon icon="CopyIcon" size="15" color="#2178fb" class="tableData" />
              </div>
            </template>
            <template #cell(action)="data">
              <div class="min-width-100 pr-request">

                <feather-icon v-b-tooltip.hover.top="'View Feedback'" icon="EyeIcon" role="button"
                  class="text-primary-icon icon-hover" size="18" @click="isOpen =true ,prData = data.item" />
                <feather-icon icon="EditIcon" role="button" :class="'text-primary-icon ml-50 icon-hover mr-1 text-dark'"
                  size="18" @click="edit(data.item)" />
              </div>
            </template>
          </b-table>
          <!-- data table complete -->
          <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img" />
          <!-- pagination section -->
        </b-col>
        <!-- pagination section -->
        <b-col v-if="totalCount > 0" cols="12"
          class="d-flex justify-content-between flex-wrap align-items-center table-pagination">
          <p v-if="totalCount > 10" class="mt-1">
            Showing {{ 1 + (currentPage - 1) * perPage }} to
            <span v-if="currentPage * perPage < totalCount">{{
              currentPage * perPage
              }}</span>
            <span v-else>{{ totalCount }}</span> out of {{ totalCount }} entries
          </p>
          <p v-else class="mt-1">
            Showing 1 to {{ totalCount }} entries out of
            {{ totalCount }} entries
          </p>
          <b-pagination v-if="totalCount > 0" v-model="currentPage" :total-rows="totalCount" :per-page="perPage"
            first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <!-- pagination section complete here -->
      </b-row>
    </div>

    <b-modal id="memberSummaryPopup" ref="my-modal" v-model="isOpen" ok-only ok-title="Accept" content-class="pr-model"
      centered size="lg">
      <template #modal-title> <span>Project Feedback Detail</span><br /> </template>
      <div class="d-flex align-items-center justify-content-between mt-2">
        <div class="d-flex align-items-center"
          v-if="prData && prData.user && prData.type && prData.type == 'individual'">
          <div>
            <b-avatar :text="prData.user.full_name | avatarText" size="40" :variant="'dark'" />
          </div>
          <div class="media-body">
            <p v-b-tooltip.hover.top class="feedback-user-name text-dark" style="margin-left: 5px"
              :title="'Employee name'">
              {{ prData.user.full_name }}
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center"
          v-if="prData && prData.project && prData.type && prData.type == 'general'">
          <div>
            <b-avatar :text="prData.project.name | avatarText" size="40" :variant="'dark'" />
          </div>
          <div class="media-body">
            <p v-b-tooltip.hover.top class="feedback-user-name text-dark" style="margin-left: 5px"
              :title="'Project name'">
              {{ prData.project.name }}
            </p>
          </div>
        </div>

        <div class="average-rating d-flex align-items-center" v-if="prData.status == 'initiated'">
          <b-badge variant="primary" class="p-50">
            <span class="fw-600 text-capitalize"> Initiated</span>
          </b-badge>
        </div>

        <div class="average-rating d-flex align-items-center" v-if="prData.rating">
          <div>
            <b-avatar v-b-tooltip.hover.top title="Rating" :text="prData.rating ? prData.rating : '-'" size="40"
              :variant="'warning'" />
          </div>
        </div>
      </div>

      <!-- Description-->
      <b-row class="d-flex row-modal custom-project-feedback mt-2">
        <div class="title-border w-100 d-flex justify-content-between project-feedback-view-header">
          <p>Client Feedback</p>
          <p> {{ prData.start_date | DateDDMMYYYYFormat }} to {{ prData.end_date | DateDDMMYYYYFormat }}</p>
        </div>
        <div class="decribe-feedback test-max ">

          <div class="decribe-feedback test-max "
            v-if="prData && prData.feedback_form_data && prData.feedback_form_data.length > 0 && prData.feedback_form_data[0].questions">

            <div v-if="prData.feedback_form_data[0].questions && prData.feedback_form_data[0].questions.length > 0">
              <div class="mb-2 project-feedback-question"
                v-for="(question, index) in prData.feedback_form_data[0].questions" :key="index">
                <div class="d-flex align-items-baseline mb-1">
                  <p class="question-class">
                    {{ index + 1 }} {{ ". " + question.question }}
                  </p>
                  <p class="align-self-baseline ml-auto">
                    <validation-provider #default="{ errors }" :name="'Rating for question-' + (index + 1)"
                      rules="required">
                      <b-form-rating readonly v-model="question.rating" :variant="errors.length ? 'danger' : 'warning'"
                        inline :class="{ 'vibrate': errors.length }" no-border class="large-rating" />
                    </validation-provider>
                  </p>
                </div>
                <div class="font-weight-bold">
                  {{ question.description ? '(' + question.description + ')' : null }}
                </div>
              </div>
            </div>
          </div>

          <div class="basic-pr-detail project-feedback-question"
            v-if="prData && prData.feedback_form_data && prData.feedback_form_data.length > 0 && prData.feedback_form_data[0].description">
            <div class="view-steps mt-1 ">
              <p class="fw-600">
                Feedback
              </p>
              {{ prData.feedback_form_data[0].description }}
            </div>
          </div>
        </div>
      </b-row>
      <template #modal-footer>
        <b-button class="d-none" />
      </template>
    </b-modal>

  </div>
</template>

<script>
import customDropdown from "@/components/dropdown/customDropdown.vue";
import AddProjectFeedback from "./AddProjectFeedback";
import OptionDropdown from "../../../components/optiondropdown/OptionDropdown.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { projectFeedbackDetail } from "../../../mixin/project/feedback/index";
import TableButton from '../../../@core/components/buttons/TableButton.vue'
import TableLoading from '../../../components/loaders/table-loading.vue';

export default {
  name: "PrRequest",
  mixins: [projectFeedbackDetail],
  components: {
    customDropdown,
    AddProjectFeedback,
    OptionDropdown,
    ValidationProvider,
    ValidationObserver,
    TableButton,
    TableLoading
  },

};
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/tesing.css";
@import "../../../assets/scss/component-css/prrequest.scss";
</style>
