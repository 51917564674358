<template>
  <div>
    <div class="card-filter-section d-flex align-items-center justify-content-between mt-0">
      <p class="task-timesheet m-0">
        Feedback Question
      </p>

      <div class="role-buttons d-flex flex-wrap align-items-center">
        <table-button v-b-toggle.feedbackQuestion btn-name="Add Question" />
      </div>

    </div>
    <!--filter start-->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown v-model="type" :options="feedbackTypeList" :search-box="false" :hide-close="true"
              label="Type" icon="EditIcon" />
          </div>
          <option-drop-down v-model="perPage" />
        </div>
        <div class="d-flex align-items-center">
          <b-input-group class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px">
            <b-form-input v-model="debouncedSearch" placeholder="Search by Question" />

            <b-input-group-append is-text>
              <feather-icon v-if="debouncedSearch" icon="XIcon" @click="debouncedSearch = null, search = null" />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button class="apply-filter filterRedesign mr-1" @click="applyFilter()">
              Apply Filter
            </button>
            <button class="apply-filter-clear filterRedesign" @click="clearFilter()">
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>

    <!--filter end-->
    <div class="table role">
      <!-- Table start from here -->
      <table-loader v-if="loading" :fields="fields" />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->
          <b-table responsive :items="items" :fields="fields" show-empty
            class="scrollable-element tableDesigns mastertblColor non-project-table">

            <template #cell(action)="data">
              <span>
                <feather-icon v-b-tooltip.hover.top="'Edit'" icon="EditIcon" role="button" class="mr-1 text-dark"
                  size="18" @click="edit(data.item)" />
                <feather-icon v-b-tooltip.hover.top="'Delete'" icon="Trash2Icon" role="button" class="text-danger"
                  size="18" @click="deleteConfirmation(data.item.id)" />
              </span>
            </template>
          </b-table>

          <!-- data table complete -->
          <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img">
        </b-col>

      </b-row>
      <!-- pagination section -->
      <Pagination v-if="totalCount > 0 && !loading" :current-page="currentPage" :per-page="perPage"
        :total-count="totalCount" @currentPage="currentPage = $event" />
      <!-- pagination section complete here -->

      <!-- from start -->
      <question-table-form @clientList="getQuestions" />
      <!-- form complete here -->
    </div>
  </div>
</template>

<script>
import customDropdown from '@/components/dropdown/customDropdown.vue'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import QuestionTableForm from '../../@core/components/table-from/FeedbackQuestionform.vue'
import { QuestionTableDetail } from '../../mixin/masterSetting/feedbackQuestion/index'

export default {
  name: 'QuestionTable',
  components: {
    QuestionTableForm,
    TableButton,
    customDropdown,
  },
  mixins: [QuestionTableDetail]
}
</script>
<style lang="scss" scoped>
@import "./../../assets/scss/component-css/masterSettingFilter.scss";
@import "../../assets/scss/variables/variables";
</style>