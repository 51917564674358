import { eventBus } from '@/main'
import debounce from 'lodash/debounce'
export const QuestionTableDetail = {
    data() {
      return {
        totalCount: 0,
        currentPage: 1,
        perPage: 25,
        loading: true,
        type: null,
        search: null,
        fields: [
          {
            key: "question",
            label: "Question",
            sortable: true,
          },
          {
            key: "description",
            label: "Description",
            sortable: true,
          },
          {
            key: "type",
            label: "Question For",
            sortable: false,
            thStyle: { width: "100px" },
            tdStyle: { width: "100px" },
            thClass: "text-start",
            tdClass: "text-start",
          },
          {
            key: "action",
            label: "Action",
            sortable: false,
            thStyle: { width: "100px" },
            tdStyle: { width: "100px" },
            thClass: "text-right",
            tdClass: "text-right",
          },
        ],
        items: [],
      };
    },
    computed: {
      debouncedSearch: {
        get() {
          return this.search
        },
        set: debounce(function (value) {
          this.search = value
          this.getQuestions()
        }, 750),
      },
    },
  
    watch: {
      /**
       * @description on page number change Call getQuestions()
       */
      currentPage: {
        handler(value) {
          if (value) {
            this.getQuestions()
          }
        },
      },
      perPage() {
        this.applyFilter()
      },
    },
    mounted() {
      this.getQuestions()
      eventBus.$on('questionList', data => {
        if (data) {
          this.applyFilter()
        }
      })
    },
  
    methods: {
      /**
       * @description Get question List
       * @method POST
       * @returns items (variable)(question list)
       */
      async getQuestions() {
        this.loading = true
        const input = {
          page: this.currentPage,
          per_page: this.perPage,
          type: this.type ?? null,
          search: this.search,
          sort_field: 'created_at',
          sort_order: 'desc',
        }
  
        const response = await this.getHTTPPostResponse(
          'admin/master/feedback-question',
          input,
        )
        if (response && response.data) {
          const { data } = response
          this.items = data.feedbackQuestions
          this.totalCount = data.count
        }
        this.loading = false
      },
  
      /**
       * @description For Edit question pass data of question to question table form
       * @emits edit-question
       */
      edit(data) {
        eventBus.$emit('edit-question', data)
      },
  
      /**
       * @description confirmation to delete question
       * @param {id} question id
       * @returns if conform to delete call delete Question function
       */
      async deleteConfirmation(id) {
        const value = await this.conformationAlert(true)
        if (value.isConfirmed === true) {
          this.deleteQuestion(id)
        }
      },
  
      /**
       * @description feedback question
       * @method DELETE
       * @param {id}  question name
       * @returns 
       */
      async deleteQuestion(id) {
        const response = await this.getHTTPDeleteResponse(
          `admin/master/feedback-question/delete/${id}`,
          {},
          true,
        )
        if (response && response.status === 200) {
          this.getQuestions()
          this.search = null
        }
      },
      /* clear filter */
      clearFilter() {
        this.type = null
        this.perPage = 10
        this.search = null
        this.getQuestions()
      },
      applyFilter(){
        if (this.currentPage == 1) {
          this.getQuestions()
        } else {
          this.currentPage = 1
        }
      }
    },
  }