import moment from "moment";

export const FeedbackView = {
  data() {
    return {
      loader: false,
      isViewModalOpen: false,
      feedbackList: [],
      feedbackReceiverDetail: null,
      rating: 0,
      status: null,
    };
  },
  props: {
    userId: {
      type: String,
      required: true,
      default: "",
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    date: {
      type: String,
      required: false,
      default: "",
    },
  },

  watch: {
    isOpen(nv) {
      this.isViewModalOpen = nv;
    },
    isViewModalOpen(nv) {
      if (nv) {
        this.getEmployeeFeedback();
      } else {
        this.$emit("modalClose", nv);
      }
    },
  },
  computed: {
    dateFormat() {
      return moment(this.date, "YYYY-Q").format("YYYY-[Q]Q");
    },
  },
  methods: {
    // Fetches employee feedback data based on the selected quarter and user ID
    async getEmployeeFeedback() {
      this.loader = true;
      const input = {
        start_date: moment(this.date, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date: moment(this.date, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
        user_id: this.userId,
      };

      const response = await this.getHTTPPostResponse(
        "feedback-initiative/get-employee-feedback",
        input,
        false
      );

      // Check if the response is valid and update feedback data
      if (response && response.status === 200) {
        if (response.data.user && response.data.user.length) {
          this.feedbackList = response.data.user[0].feedback_forms;
          this.rating = response.data.user[0].rating || 0;
          this.status = response.data.user[0].status;
          this.feedbackReceiverDetail = response.data.user[0].user;
        }
      }

      // Hide loader after a short delay
      setTimeout(() => {
        this.loader = false;
      }, 200);
    },

    // Returns the variant (style) for the badge based on the feedback status
    getStatusVariant(status) {
      return status == "close"
        ? "secondary"
        : status == "submitted"
        ? "success"
        : status == "inprogress"
        ? "dark"
        : "primary";
    },
  },
};
