
import { eventBus } from '@/main'
export const QuestionForm = {
  data() {
    return {
      btnLoader: false,
      type: "employee",
      question: null,
      isAddRemarkList: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
      id: null,
      description: null,
    };
  },
  mounted() {
    eventBus.$on('edit-question', data => {
      if (data) {
        this.id = data.id
        this.question = data.question
        this.type = data.type
        this.description = data.description; 
        this.$root.$emit('bv::toggle::collapse', 'feedbackQuestion')
      }
    })
  },

  methods: {
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.btnLoader = false
      this.type = 'employee'
      this.question = null
      this.description = null;
    },

    /**
     * check the form validation
     * @returns if success true then call saveQuestionDtl Method
     */
    validationForm() {
      this.$refs.questionObserver.validate().then(success => {
        if (success) {
          this.saveQuestionDtl('add')
        }
      })
    },
    /**
     * Add & Update the project-form details & call project listing in project-table
     * @emits projectList
     * @returns project detail add & update
     */
    async saveQuestionDtl() {
      this.btnLoader = true
      const input = {
        question: this.question,
        type: this.type,
        description: this.description,
      };
      let response = null
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/feedback-question/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/feedback-question/create',
          input,
          true,
        )
      }
      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'feedbackQuestion')
        eventBus.$emit('questionList', true)
      }
      this.btnLoader = false
    },
    /**
     * Clear form details
     */
    clearData() {
      this.id = null
      this.btnLoader = false
      this.type = 'employee'
      this.question = null
      this.description = null;
      this.$refs.questionObserver.reset()
    },
  },
}
