import moment from "moment";
export const feedbackFormDetail = {
  data() {
    return {
      loader: false,
      feedbackReceiver: null,
      questions: [],
      feedbackFormData: null,
      projects: [],
      feedbackFormStatus: null,
      description: null,
      type: null,
      projectDetail: null,
      projectFeedback: null,
    };
  },
  mounted() {
    if (this.$route.query.id || this.$route.params.id) {
      this.getQuestionList();
    }
  },
  computed: {
    avgRating() {
      return (
        this.questions.reduce((sum, q) => sum + q.rating, 0) /
        this.questions.length
      ).toFixed(2);
    },
    FormattedDateRange() {
      return this.feedbackFormData &&
        this.feedbackFormData.start_date &&
        this.feedbackFormData.end_date
        ? `${moment(this.feedbackFormData.start_date).format(
            "Do MMMM YYYY"
          )} to ${moment(this.feedbackFormData.end_date).format(
            "Do MMMM YYYY"
          )}`
        : null;
    },
  },
  methods: {
    /**
     * @description Get Feedback Question List
     * @method POST
     * @returns
     */
    async getQuestionList() {
      this.loader = true;
      let token = this.$route.query.token;
      let id = this.$route.query.id
        ? this.$route.query.id
        : this.$route.params.id
        ? this.$route.params.id
        : null;
      const input = {
        id: id,
        token: token,
        isHideErrorMsg: true,
      };

      let url = window.location.href.includes("project-feedback-form")
        ? "project-feedback-form-data/question-list"
        : "feedback-form-data/question-list";

      const response = await this.getHTTPPostResponse(url, input);

      if (response && response.data) {
        const { data } = response;
        this.feedbackFormData = data.feedbackFormData;
        this.questions = data.feedbackFormData.questions;
        this.feedbackReceiver = data.feedbackFormData.feedback_receiver;
        this.projects = data.feedbackFormData.projects;
        this.type = data.type;
        this.projectDetail = data.feedbackFormData.project;
        this.projectFeedback = data.feedbackFormData.project_feedback;
        if (data.feedbackFormData.is_submit) {
          this.feedbackFormStatus = "alreadySubmitted";
        }
      } else {
        if (response && response.response && response.response.data.data) {
          let data = response.response.data.data;
          if (data && data.is_unauthorize) {
            this.feedbackFormStatus = "unauthorized";
          }
          if (data && data.is_form_expire) {
            this.feedbackFormStatus = "formExpired";
          }
        }
      }
      this.loader = false;
    },
    /**
     * check the form validation
     * @returns if success true then call sendFeedback Method
     */
    validationForm() {
      this.$refs.feedbackObserverRules.validate().then((success) => {
        if (success) {
          this.sendFeedback();
        }
      });
    },

    // call send feedback Api.
    async sendFeedback() {
      this.loader = true;
      const input = {
        token:
          this.$route.query && this.$route.query.token
            ? this.$route.query.token
            : null,
        id: this.$route.params.id,
        questions: this.questions,
        rating: this.avgRating,
        description: this.description,
      };

      let url = window.location.href.includes("project-feedback-form")
        ? "project-feedback-form-data/update-employee-and-client-feedback"
        : "feedback-form-data/update-employee-and-client-feedback";
        
      const response = await this.getHTTPPostResponse(url, input, false);
      if (response && response.status == 200) {
        this.feedbackFormStatus = "thankYou";
      }
      this.loader = false;
    },

    // Clear Form data
    clearAll() {
      this.questions = [];
      this.$refs.feedbackObserverRules.reset();
    },

  },
};
