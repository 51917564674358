<template>
  <!-- this from use in role and client table -->
  <div class="custom-project-form">
    <validation-observer ref="prRequestObserver">
      <b-sidebar id="addEditRequest" ref="addEditRequest" bg-variant="white" right backdrop shadow class="sidebar-form"
        @hidden="clearData()">
        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} Request
        </h3>
        <div class="line user-underline" />

        <!--Title start-->
        <b-form-group label="Title" label-for="clientname" class="required-form-filed">
          <validation-provider #default="{ errors }" name="Title" rules="required|max:512">
            <b-form-input id="name" v-model="title" :state="errors.length > 0 ? false : null" placeholder="Title"
              name="clientname" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!--Title end-->

        <!-- platform-->
        <b-form-group label="PR Link" label-for="PR Link" class="required-form-filed">
          <validation-provider #default="{ errors }" name="PR Link" rules="required|url|https">
            <b-form-input id="Platform" v-model="platform" :state="errors.length > 0 ? false : null"
              placeholder="Enter PR Link " name="PR Link" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="estimated_start_date">Date <span class="text-danger">*</span></label>
          <validation-provider #default="{ errors }" name="Date" rules="required">
            <b-input-group class="input-group-merge">
              <flat-pickr v-model="date" class="form-control" :config="configEstimatedStartDate" placeholder="Date"
                name="estimated_start_date" />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="estimated_start_date">Time <span class="text-danger">*</span></label>
          <validation-provider #default="{ errors }" name="Time" rules="required">
            <b-form-input id="myInput" v-model="time" class="select-time"
              :style="$store.state.app.isFullScreenModal ? 'text-align: start !important;' : null" name="start_time"
              type="time" placeholder="03:29 PM" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group class="description">
          <label for="estimated_start_date">Description</label>
          <quill-editor  id="issue_discription"
            v-model="description" name="issue discription"
            :options="descriptionEditorOption">
            <div id="toolbar1" slot="toolbar">
              <!-- Add font size dropdown -->
              <select class="ql-size">
                <option value="small" />
                <!-- Note a missing, thus falsy value, is used to reset to default -->
                <option selected />
                <option value="large" />
                <option value="huge" />
              </select>

              <select class="ql-font">
                <option selected="selected" />
                <option value="serif" />
                <option value="monospace" />
              </select>
              <!-- Add a bold button -->
              <button class="ql-bold">
                Bold
              </button>
              <button class="ql-italic">
                Italic
              </button>
              <button class="ql-underline">
                Underline
              </button>

              <button class="ql-clean" />
              <select class="ql-align">
                <option selected="selected" />
                <option value="center" />
                <option value="right" />
                <option value="justify" />
              </select>
              <button id="ql-link" class="ql-link">
                <feather-icon icon="Link2Icon" color="white" />
              </button>

              <button class="ql-list" value="ordered" />
              <button class="ql-list" value="bullet" />
              <!-- You can also add your own -->
            </div>
          </quill-editor>
        </b-form-group>

        <template #footer>
          <!--form footer start-->
          <div class="form-button d-flex my-2">
            <button class="btn side-bar-btn" :disabled="btnLoader" @click.prevent="validationForm">
              <b-spinner v-if="btnLoader" small class="spinner" />
              {{ id ? "Update" : "Save" }}
              <!-- v-if="btnLoader" -->
            </button>
            <button class="btn side-bar-btn" @click="clearData()">
              Clear
            </button>
          </div>
          <!--form footer end-->
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import { eventBus } from '@/main'

export default {
  name: 'ClientTableForm',
  components: {
    ValidationObserver,
    ValidationProvider,
    // VSelect,
    flatPickr,
    quillEditor
  },
  data() {
    return {
      id: null,
      description: null,
      title: null,
      btnLoader: false,
      country: null,
      countries: [],
      date: moment().format('YYYY-MM-DD'),
      configEstimatedStartDate: {
        maxDate: new Date(),
        dateFormat: 'Y/m/d',
      },
      time: moment().format('HH:mm'),
      platform: null,
      descriptionEditorOption: {
        modules: {
          toolbar: '#toolbar1',
        },
        placeholder: 'Type anything',
      },
    }
  },
  mounted(){
    eventBus.$on('edit-pr-request', data => {
      if (data) {
        this.id = data.id
        this.title = data.title
        this.description = data.description
        this.platform = data.platform
        this.date =data.date   
        this.time = data.time
        this.$root.$emit('bv::toggle::collapse', 'addEditRequest')
      }
    })
  },
  methods: {
    /**
       * check the form validation
       * @returns if success true then call addPrRequest Method
       */
    validationForm() {
      this.$refs.prRequestObserver.validate().then(success => {
        if (success) {
          this.addPrRequest()
        }
      })
    },

    /**
       * Add & Update the clientTableForm details & call client listing in clienttable
       * @emits clientList
       * @returns client detail add & update
       */
    async addPrRequest() {
      this.btnLoader = true
      let input = {
        id: this.id,
        title: this.title,
        date: this.date,
        project_id: this.$route.params.id3,
        time: this.time,
        platform: this.platform,
        description: this.description
      }
      let url = this.id ? 'project/pr-request/update' : 'project/pr-request/create'
      const response = await this.getHTTPPostResponse(
        url,
        input,
        true,
      )
      if (response && response.status === 200) {
        this.$emit('reloadList', true)
        this.$root.$emit('bv::toggle::collapse', 'addEditRequest')
      }
      setTimeout(() => {
        this.btnLoader = false
      }, 200)
    },

    /**
       *  Clear form details
       */
    clearData() {
      this.id = null
      this.title = null
      this.date = moment().format('YYYY-MM-DD')
      this.time = moment().format('HH:mm')
      this.platform = null
      this.description = null
      this.$refs.prRequestObserver.reset()
    },
  },
}
</script>