var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-project-form"},[_c('validation-observer',{ref:"projectFeedbackObserver"},[_c('b-sidebar',{ref:"addEditRequest",staticClass:"sidebar-form",attrs:{"id":"addEditRequest","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":function($event){return _vm.clearData()}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.projectFeedbackLink && !_vm.projectFeedbackLink.length)?_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Save")+" ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])]):_vm._e()]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.projectFeedbackLink && _vm.projectFeedbackLink.length ? 'Summary' : _vm.id ? "Edit" : "Add")+" Feedback ")]),_c('div',{staticClass:"line user-underline"}),(_vm.projectFeedbackLink && !_vm.projectFeedbackLink.length)?_c('div',[_c('b-form-group',{staticClass:"required-form-filed",attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",class:{ 'cursor-default': _vm.id },attrs:{"hide-details":"","name":"type","label":"name","options":_vm.types,"disabled":_vm.id ? true : false,"reduce":function (type) { return type.value; },"placeholder":"Select Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,212520726)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"start_and_end_date"}},[_vm._v("Start And End Date Range"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",class:{ 'cursor-default': _vm.id },attrs:{"config":_vm.startAndEndDate,"disabled":_vm.id ? true : false,"placeholder":"Start and end date range","name":"start_and_end_date"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4187649030)})],1),_c('b-form-group',[_c('label',{attrs:{"for":"form_expire_date"}},[_vm._v("Expire Date")]),_c('validation-provider',{attrs:{"name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":_vm.expireDate,"placeholder":"Feedback expire date","name":"form_expire_date"},model:{value:(_vm.form_expire_date),callback:function ($$v) {_vm.form_expire_date=$$v},expression:"form_expire_date"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"CalendarIcon"}})],1)],1)]}}],null,false,4160278138)})],1),(_vm.type === 'individual')?_c('b-form-group',[_c('label',{attrs:{"for":"members"}},[_vm._v("Members "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('validation-provider',{attrs:{"name":"Members","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-tags-timesheet3",class:{ 'cursor-default': _vm.id },attrs:{"id":"tags","hide-details":"","multiple":"","name":"tags","label":"label","options":_vm.projectUsers,"reduce":function (user) { return user.value; },"placeholder":"Select Any Members","disabled":_vm.id ? true : false,"close-on-select":false},model:{value:(_vm.members),callback:function ($$v) {_vm.members=$$v},expression:"members"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,758450602)})],1):_vm._e()],1):_c('div',[_c('div',{staticClass:"table role test-plan-table"},[(_vm.loader)?_c('TableLoader',{attrs:{"fields":_vm.fields}}):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table pr-table",attrs:{"responsive":"","items":_vm.projectFeedbackLink,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('p',{staticClass:"tableData"},[_vm._v(" "+_vm._s(data.item.type === 'general' ? (data.item.project && data.item.project.name ? data.item.project.name : 'No Project') : (data.item.user && data.item.user.name ? data.item.user.full_name : 'No Receiver'))+" ")])]}},{key:"cell(copyLink)",fn:function(data){return [_c('div',{staticClass:"copy-link",on:{"click":function($event){return _vm.copyToClipboard(data.item.url)}}},[_c('feather-icon',{staticClass:"tableData",attrs:{"icon":"CopyIcon","size":"15","color":"#2178fb"}})],1)]}}])}),(_vm.projectFeedbackLink.length === 0)?_c('img',{staticClass:"no-data-img",attrs:{"src":require("@/assets/images/nodatafound/nodata.svg"),"alt":"no-data"}}):_vm._e()],1)],1)],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }