export const statusCardDetail = {
    name: "FeedBackFormStatus",
    props: {
      status: {
        type: String,
        required: false,
        default: "",
      },
    },
    data() {
      return {
        feedbackStates: {
          thankYou: {
            icon: "CheckCircleIcon",
            iconClass: "text-success",
            title: "Thank You!",
            message:
              "Your feedback has been successfully received. We appreciate your valuable input.",
          },
          alreadySubmitted: {
            icon: "InfoIcon",
            iconClass: "text-success",
            title: "Already Submitted",
            message: "You have already submitted your valuable feedback.",
          },
          formExpired: {
            icon: "AlertTriangleIcon",
            iconClass: "text-danger",
            title: "Form Expired",
            message:
              "The Feedback form you are trying to access is no longer available.",
          },
          tokenExpired: {
            icon: "AlertTriangleIcon",
            iconClass: "text-danger",
            title: "Token Expired",
            message:
              "The token provided is invalid. Please check the feedback form URL and try again.",
          },
          unauthorized: {
            icon: "LockIcon",
            iconClass: "text-warning",
            title: "Unauthorized",
            message: "You are not authorized to access this feedback form.",
          },
        },
        statusData: {}, 
      };
    },
    computed: {
      computedStatusData() {
        return this.feedbackStates[this.status];
      },
    },
    watch: {
      status: {
        immediate: true,
        handler() {
          this.statusData = this.computedStatusData;
        },
      },
    },

  };