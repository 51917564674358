<template>
  <div>
    <div class="viewEdit">
      <!--heading-->
      <b-card v-if="callFrom == 'list'" class="mb-0 timesheet-header">
        <div class="card-header-edit d-flex justify-content-between">
          <p class="title-pr-">PR Request List</p>
          <div id="date-select">
            <custom-calender :start="startDate" :end="endDate" :active-btn="activeBtn"
              :date-range-config="dateRangeConfig" :from="'project'" @date="updateDate" />
          </div>
          <div />
        </div>
      </b-card>
      <!--filter start-->
      <div class="pr-filter">
        <div class="d-flex">
          <p class="filter-title" style="align-self: center">Filters:</p>
          <custom-dropdown v-if="callFrom == 'list'" v-model="projectIds" :options="filterProjectOptions" label="Project"
            icon="EditIcon" multiple />

          <custom-dropdown v-model="pr_generated_by" :options="filterUserOptions" multiple label="Assignee"
            icon="EditIcon" />

          <custom-dropdown v-if="isAccess" v-model="reviewBy" :options="filterReviewerOptions" label="Reviewer"
            icon="EditIcon" multiple />

          <custom-dropdown v-model="filterStatus" :options="statusOptions" label="Status" icon="EditIcon"
            :search-box="false" />

          <div style="align-self: center">
            <OptionDropdown v-model="perPage" />
          </div>
        </div>
        <div v-if="callFrom == 'project'">
          <custom-calender :start="startDate" :end="endDate" :active-btn="activeBtn"
            :date-range-config="dateRangeConfig" :from="'project'" @date="updateDate" />
        </div>
        <div class="d-flex justify-content-center align-item-center gap-12">
          <b-input-group class="input-group-merge" style="
              min-width: 250px;
              max-width: 200px;
              width: 250px;
              height: 33px;
            ">
            <b-form-input v-model="debouncedSearch" placeholder="search by title" style="height: 33px" />

            <b-input-group-append is-text>
              <feather-icon v-if="debouncedSearch" icon="XIcon" @click="(debouncedSearch = null), (search = null)" />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <button class="apply-filter filterRedesign" @click="applyFilter()" style="height: 33px">
            Apply Filter
          </button>
          <button class="apply-filter-clear filterRedesign" @click="clearFilter()" style="height: 33px">
            Clear
          </button>

          <b-button v-if="callFrom == 'project'" v-b-toggle.addEditRequest
            class="btn-role btn-secondary p-75 pr-add-request" variant="secondary" style="height: 33px">
            Add Request
          </b-button>
        </div>
      </div>
      <!--filter end-->
    </div>
    <AddRequest @reloadList="applyFilter()" />

    <div class="d-flex bg-white mb-2 flex-wrap count-card-shadow">
      <div v-for="(card, index) in cardArray" :key="index" class="box-card-design cursor-pointer" :class="card.class"
        @click="updateFilterStatus(card.label)">
        <div>{{ card.key }}</div>
        <p class="count" :class="card.label != 'success_ratio' ? 'cursor-pointer' : null">
          {{ card.label == "success_ratio" ? card.count + " %" : card.count }}
        </p>
      </div>
    </div>

    <div class="table role test-plan-table">
      <TableLoader v-if="loader" :fields="fields" />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->
          <b-table responsive :items="prRequests" :fields="tableColumn" show-empty
            class="scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table pr-table">
            <template #cell(action)="data">
              <div class="min-width-100 pr-request">

                <feather-icon v-b-tooltip.hover.top="'View PR'" icon="EyeIcon" role="button"
                  class="text-primary-icon icon-hover" size="18" @click="(isOpen = true), (prData = data.item)" />


                <feather-icon v-b-tooltip.hover.top="data.item.created_by.id == userInfo.id &&
                    data.item.status == 'open'
                    ? 'Edit'
                    : null
                  " icon="EditIcon" role="button" :class="'text-primary-icon ml-50 icon-hover'" size="18"
                  @click="edit(data.item)" v-if="data.item.created_by.id == userInfo.id &&
                      data.item.status == 'open'" />

                <feather-icon v-b-tooltip.hover.top="data.item.created_by.id == userInfo.id &&
                    data.item.status == 'open'
                    ? 'Delete'
                    : null
                  " icon="Trash2Icon" role="button" :class="data.item.created_by.id == userInfo.id &&
                      data.item.status == 'open'
                      ? 'text-danger ml-50 icon-hover'
                      : 'text-danger pr-delete-disable ml-50'
                    " size="18" @click="
                    data.item.created_by.id == userInfo.id &&
                      data.item.status == 'open'
                      ? deleteConfirmation(data.item.id)
                      : null
                    " />
                <feather-icon v-b-tooltip.hover.top="'Redirect to PR'" icon="NavigationIcon" role="button"
                  class="text-primary-icon ml-50 icon-hover" size="18" @click="openPR(data.item.platform)" />
              </div>
            </template>

            <template #cell(project)="data">
              <div class="min-width">
                {{ data.item.project && data.item.project.name }}
              </div>
            </template>
            <template #cell(date)="data">
              <div class="min-width">
                {{ data.item.date | DateDDMMYYYYFormat
                }}{{ " " + data.item.time }}
              </div>
            </template>
            <template #cell(title)="data">
              <div v-b-tooltip.hover.top class="min-title-width-300" :title="data.item.title">
                {{
                data.item.title.length > 100
                ? data.item.title.substring(0, 100) + "..."
                : data.item.title
                }}
              </div>
            </template>
            <template #cell(platform)="data">
              <div v-b-tooltip.hover.top class="min-title-width small-letter" :title="data.item.platform">
                {{
                data.item.platform && data.item.platform.length > 40
                ? data.item.platform.substring(0, 40) + "..."
                : data.item.platform
                ? data.item.platform
                : "N/A"
                }}
              </div>
            </template>

            <template #cell(review_by)="data">
              <div class="min-width-100">
                {{
                data.item.pr_review_by && data.item.pr_review_by.full_name
                ? data.item.pr_review_by.full_name
                : "Pending"
                }}
              </div>
            </template>
            <template #cell(member)="data">
              <div class="min-width-100">
                {{
                data.item.created_by && data.item.created_by.full_name
                ? data.item.created_by.full_name
                : "N/A"
                }}
              </div>
            </template>

            <template #cell(status)="data">
              <div class="d-flex justify-content-center align-item-center">
                <button v-if="
                    data.item.is_reviewed ||
                    (userInfo.role != 'SA' &&
                      data.item.created_by.reporting_to != userInfo.id)
                  " :class="
                    data.item.status == 'approve'
                      ? 'pr-btn-success'
                      : data.item.status == 'reject'
                      ? 'pr-btn-danger'
                      : 'pr-btn-primary'
                  " class="w-50 text-capitalize pr-status-btn" style="cursor: default">
                  {{
                  data.item.status == "approve"
                  ? "Approved"
                  : data.item.status == "reject"
                  ? "Rejected"
                  : "Open"
                  }}
                </button>
                <v-select v-if="
                    data.item.status == 'open' &&
                    (userInfo.role == 'SA' ||
                      data.item.created_by.reporting_to == userInfo.id)
                  " :ref="'statusDropdown_' + data.index" v-model="data.item.status" name="status" label="label"
                  :options="statusOptions" :disabled="loader" class="select-size-sm select-user-nav min-width-100"
                  :class="data.index" :reduce="(statusOptions) => statusOptions.value" :clearable="false" @open="
                    data.index == prRequests.length - 1
                      ? addclass(data.index)
                      : null
                  " @close="
                    data.index == prRequests.length - 1
                      ? removeclass(data.index)
                      : null
                  " @input="changeStatus(data.item.id, data.item.status)">
                  <template v-slot:option="option">
                    {{ option.label }}
                  </template>
                  <template #selected-option="option">
                    <div style="display: flex; align-items: baseline">
                      <span class="select-user-name">{{ option.label }} </span>
                    </div>
                  </template>
                </v-select>
              </div>
            </template>
          </b-table>
          <!-- data table complete -->
          <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img" />
          <!-- pagination section -->
        </b-col>
        <!-- pagination section -->
        <b-col v-if="totalCount > 0" cols="12"
          class="d-flex justify-content-between flex-wrap align-items-center table-pagination">
          <p v-if="totalCount > 10" class="mt-1">
            Showing {{ 1 + (currentPage - 1) * perPage }} to
            <span v-if="currentPage * perPage < totalCount">{{
              currentPage * perPage
              }}</span>
            <span v-else>{{ totalCount }}</span> out of {{ totalCount }} entries
          </p>
          <p v-else class="mt-1">
            Showing 1 to {{ totalCount }} entries out of
            {{ totalCount }} entries
          </p>
          <!-- <p></p> -->
          <b-pagination v-if="totalCount > 0" v-model="currentPage" :total-rows="totalCount" :per-page="perPage"
            first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
        <!-- pagination section complete here -->
      </b-row>
    </div>

    <b-modal id="memberSummaryPopup" ref="my-modal" v-model="isOpen" ok-only ok-title="Accept" content-class="pr-model"
      centered size="lg">
      <template #modal-title> <span>PR Request Detail</span><br /> </template>
      <!-- Title-->
      <b-row class="d-flex mt-25 row-modal">
        <p class="title-border w-100">Title</p>
        <p class="title-border-desc bottom-border">
          {{ prData && prData.title }}
        </p>
      </b-row>
      <!-- Description-->
      <b-row class="d-flex mt-1 row-modal">
        <p class="title-border w-100">Description</p>
        <div class="view-steps mt-1 description-padding" v-if="
            prData && prData.description && checkDesription(prData.description)
          ">
          <div v-html="prData.description"></div>
        </div>
        <p class="pr-desc description-padding" v-else>N/A</p>
      </b-row>
      <!--Rejection reject-->
      <b-row class="d-flex mt-1 row-modal" v-if="prData && prData.status == 'reject'">
        <p class="title-border w-100">Rejection Reason</p>
        <div class="view-steps mt-1 description-padding" v-if="prData.rejection_reason">
          <div>{{ prData.rejection_reason }}</div>
        </div>
        <p class="pr-desc description-padding" v-else>N/A</p>
      </b-row>
      <!-- Other Details-->
      <b-row class="d-flex mt-1 row-modal">
        <p class="title-border w-100">Other Detail</p>
        <div class="basic-pr-detail">
          <!-- Project -->
          <div class="w-33">
            <p class="title-border">Project</p>
            <div class="title-border-desc bottom-border">
              <div class="d-flex align-item-center">
                <b-avatar v-if="prData.project" :text="prData.project.name | avatarText" size="40"
                  class="summary-projectnew-projectlogo" :style="
                    prData.project && prData.project.color
                      ? { 'background-color': '#' + prData.project.color }
                      : null
                  " />
                <p class="summary-projectnew-title align-self-center ml-1" :style="
                    prData.project && prData.project.color
                      ? { color: '#' + prData.project.color }
                      : null
                  ">
                  {{
                  prData && prData.project && prData.project.name
                  ? prData.project.name
                  : "N/A"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- PR Generated By -->
          <div class="w-33">
            <p class="title-border">PR Generated By</p>
            <div class="title-border-desc bottom-border">
              <div class="d-flex align-item-center">
                <b-avatar v-if="
                    prData && prData.created_by && prData.created_by.full_name
                  " :text="prData.created_by.full_name | avatarText" size="40" class="summary-projectnew-projectlogo"
                  :variant="randomVariant(1, 5)" />
                <p class="summary-projectnew-title align-self-center ml-1">
                  {{
                  prData && prData.created_by && prData.created_by.full_name
                  ? prData.created_by.full_name
                  : "N/A"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- PR Reviewed By -->
          <div class="w-33">
            <p class="title-border">PR Reviewed By</p>
            <div class="title-border-desc bottom-border">
              <div class="d-flex align-item-center">
                <b-avatar v-if="
                    prData &&
                    prData.pr_review_by &&
                    prData.pr_review_by.full_name
                  " :text="prData.pr_review_by.full_name | avatarText" size="40" class="summary-projectnew-projectlogo"
                  :variant="randomVariant(1, 5)" />
                <p class="summary-projectnew-title align-self-center ml-1">
                  {{
                  prData &&
                  prData.pr_review_by &&
                  prData.pr_review_by.full_name
                  ? prData.pr_review_by.full_name
                  : "Pending"
                  }}
                </p>
              </div>
            </div>
          </div>
          <!-- Date & Time -->
          <div class="w-33">
            <p class="title-border">Date & Time</p>
            <div class="title-border-desc bottom-border" style="margin: 4px">
              <div class="d-flex align-item-center">
                <p class="summary-projectnew-title align-self-center pending-review d-flex align-item-center">
                  {{ prData && prData.date }} {{ prData && prData.time }}
                </p>
              </div>
            </div>
          </div>
          <!-- Status -->
          <div class="w-33">
            <p class="title-border">Status</p>
            <div class="title-border-desc bottom-border">
              <button :class="
                  prData.status == 'approve'
                    ? 'pr-btn-success'
                    : prData.status == 'reject'
                    ? 'pr-btn-danger'
                    : 'pr-btn-primary'
                " class="w-50 text-capitalize pr-status-btn modal-status-btn" style="cursor: default">
                {{
                prData.status == "approve"
                ? "Approved"
                : prData.status == "reject"
                ? "Rejected"
                : "Open"
                }}
              </button>
            </div>
          </div>
          <!-- PR Detail -->
          <div class="w-33">
            <p class="title-border">PR Detail</p>
            <div class="title-border-desc bottom-border">
              <button v-b-tooltip.hover.top title="Redirect to PR" class="text-capitalize review-btn"
                @click="openPR(prData.platform)">
                <feather-icon icon="NavigationIcon" role="button" class="text-light-icon icon-hover" size="18" />
              </button>
            </div>
          </div>
        </div>
      </b-row>
      <template #modal-footer>
        <b-button class="d-none" />
      </template>
    </b-modal>

    <b-modal id="rejection" v-model="isRejected" ok-only ok-title="Accept" centered size="lg"
      class="addtask-modal rejected-modal" :show-ok="false">
      <template #modal-title>
        <h3 class="reject-modal-header">
          <span> Rejected Reason </span>
        </h3>
      </template>

      <validation-observer ref="rejectReasonRules">
        <b-row class="addtask-content">
          <!-- Select Title Block -->
          <b-col cols="12">
            <b-form-group label-for="title" class="m-1 to-do-input auto-resize-teaxtarea">
              <validation-provider #default="{ errors }" name="Rejected reason" rules="required">
                <textarea ref="textarea" v-model="rejectionReason" name="Rejected reason" placeholder="Reason ..."
                  rows="5" @input="resizeTextarea" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="text-center w-100">
          <div>
            <b-button variant="primary" size="sm" class="btnClose reject-modal-btn" @click="validationForm()">
              Add Reason
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import customDropdown from "@/components/dropdown/customDropdown.vue";
import debounce from "lodash/debounce";
import AddRequest from "./AddRequest";
import OptionDropdown from "../../../components/optiondropdown/OptionDropdown.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { eventBus } from '@/main'

export default {
  name: "PrRequest",
  components: {
    customDropdown,
    AddRequest,
    OptionDropdown,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    callFrom: {
      type: String,
      required: false,
      default: "list",
    },
    projectDtl: {
      type: Object,
      required: false,
      default: () => {},
    },
  },

  data() {
    return {
      loader: false,
      isOpen: false,
      totalCount: 0,
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.per_page || 25,
      rangeDate: null,
      is_active: "active",
      isRejected: false,
      rejectionReason: null,
      id: null,
      fields: [
        {
          key: "project",
          label: "Project",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "200px !important",
          },
        },
        {
          key: "date",
          label: "Date",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "200px !important",
          },
        },
        {
          key: "title",
          label: "title",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "350px !important",
          },
        },
        {
          key: "platform",
          label: "PR Link",
          tdStyle: {
            textAlign: "center !important",
            minWidth: "350px !important",
          },
        },
        {
          key: "review_by",
          label: "Review By",
          tdStyle: { textAlign: "center !important" },
        },
        {
          key: "member",
          label: "Pr Generated By",
          tdStyle: { textAlign: "center !important" },
        },
        {
          key: "status",
          label: "Status",
          tdStyle: { textAlign: "center !important" },
        },
        {
          key: "action",
          label: "Action",
          tdStyle: { textAlign: "center !important" },
        },
      ],
      prRequests: [],
      activeBtn: "active",
      loading: false,
      cardArray: [
        {
          key: "total",
          label: "total",
          class: "total",
          count: 0,
        },
        {
          key: "open",
          label: "open",
          class: "not-a-issuecard",
          count: 0,
        },
        {
          key: "approved",
          label: "approve",
          class: "resolved",
          count: 0,
        },
        {
          key: "rejected",
          label: "reject",
          class: "open",
          count: 0,
        },
        {
          key: "success ratio",
          label: "success_ratio",
          class: "resolved cursor-default",
          count: 0,
        },
      ],
      pr_generated_by: [],
      reviewBy: [],
      dateRangeConfig: {
        mode: "range",
        inline: true,
        maxDate: moment().endOf("week").add(1, "d").format("YYYY-MM-DD"),
      },
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      search: this.$route.query.search || null,
      projectIds: this.$route.params.id3 ? [this.$route.params.id3] : [],
      assigneeList: [],
      reviewerList: [],
      filterStatus: null,
      projects: [],
      projectsList: [],
      prData: {},
    };
  },

  computed: {
    tableColumn() {
      return this.callFrom == "list" ? this.fields : this.fields.slice(1);
    },
    filterUserOptions() {
      const users = this.assigneeList.map((element) => ({
        value: element.id,
        label: element.full_name,
      }));

      // Sort users list by label
      return users;
    },
    filterReviewerOptions() {
      const users = this.reviewerList.map((element) => ({
        value: element.id,
        label: element.full_name,
      }));

      // Sort users list by label
      return users;
    },
    statusOptions() {
      return [
        { value: "open", label: "Open" },
        { value: "approve", label: "Approve" },
        { value: "reject", label: "Reject" },
      ];
    },
    debouncedSearch: {
      get() {
        return this.search;
      },
      set: debounce(function (value) {
        this.search = value;
        this.applyFilter();
      }, 500),
    },
    filterProjectOptions() {
      const allProject = [];
      this.projectsList.forEach((element) => {
        allProject.push({
          value: element.id,
          label: element.name,
          type: element.work_type,
        });
      });

      // Sort the allProject array alphabetically by project name (label)
      allProject.sort((a, b) => a.label.localeCompare(b.label));

      return allProject;
    },
    isAccess() {
      if (this.userInfo?.role === "SA" || this.userInfo?.is_resource_managing) {
        return true;
      }

      const user = this.projectDtl?.project_user?.find(
        (pu) => pu.id === this.userInfo.id
      );

      return (user?.access_rights && user?.access_rights != "member") || false;
    },
  },
  watch: {
    currentPage(nv) {
      if (this.$route.query.currentPage !== nv) {
        this.prRequestList();
      }
    },
    perPage(nv) {
      if (this.$route.query.perPage !== nv) {
        if (this.currentPage == 1) {
          this.prRequestList();
        } else {
          this.currentPage = 1;
        }
      }
    },
    projectIds(nv) {
      this.getAssigneeList();
    },
    isRejected(nv) {
      this.rejectionReason = null;
      if (nv == false) {
        this.items = [];
        this.prRequestList();
        this.prRequestStatusCount();
      }
    },
  },
  mounted() {
    const queryParams = this.$route.query;

    if (queryParams) {
      this.startDate = queryParams.startDate || this.startDate;
      this.endDate = queryParams.endDate || this.endDate;
      this.reviewBy = queryParams.reviewed_by
        ? queryParams.reviewed_by.split(",")
        : [];
      this.pr_generated_by = queryParams.pr_generated_by
        ? queryParams.pr_generated_by.split(",")
        : [];
      this.filterStatus = queryParams.status || this.filterStatus;
      this.projects = queryParams.projects
        ? queryParams.projects.split(",")
        : this.projects;
    }
    this.applyFilter();
    this.getAssigneeList();
    this.getReviewerList();

    if (this.callFrom == "list") {
      this.UserProjectList();
    }
  },
  methods: {
    updateQueryParams() {
      const query = this.getQueryParams();
      // Push the query parameters to the route
      this.routeQueryUpdate(query);
    },
    getQueryParams() {
      const queryParams = {
        startDate: this.startDate,
        endDate: this.endDate,
        projectIds: this.projectIds,
        reviewed_by: this.reviewBy.join(",") ?? null,
        pr_generated_by: this.pr_generated_by.join(",") ?? null,
        status: this.filterStatus,
        projects: this.projects.join(",") ?? null,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
      };

      // Update the route with query parameters
      // Filter out null or empty values from the query object
      return Object.keys(queryParams).reduce((acc, key) => {
        if (queryParams[key]) acc[key] = queryParams[key];
        return acc;
      }, {});
    },
    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null;
      this.endDate = obj && obj.endDate ? obj.endDate : null;
      this.activeBtn = obj && obj.active ? obj.active : null;
      this.applyFilter();
    },

    validationForm() {
      this.$refs.rejectReasonRules.validate().then((success) => {
        if (success) {
          this.updateStatus("reject");
        }
      });
    },

    async changeStatus(id, status) {
      this.id = id;
      const label = status == "approve" ? "Approve" : "Reject";
      const title = `Are you sure want to ${label} ?`;
      const confirmation = await this.conformationAlert(false, title, label);

      if (confirmation.isConfirmed) {
        if (status == "approve") {
          this.updateStatus(status);
        } else {
          this.rejectionReason = null;
          this.isRejected = true;
        }
      } else {
        this.items = [];
        this.prRequestList();
      }
    },

    async updateStatus(status) {
      this.btnLoader = true;
      const input = {
        id: this.id,
        status,
        rejection_reason: this.rejectionReason,
      };
      const response = await this.getHTTPPostResponse(
        "project/pr-request/update-status",
        input,
        true
      );

      if (response && response.status === 200) {
        
        if (status == "approve") {
          this.prRequestList();
          this.prRequestStatusCount();
        } else {  
          this.isRejected = false;
        }
      }
      setTimeout(() => {
        this.btnLoader = false;
      }, 200);
    },

    clearFilter() {
      this.startDate = moment().startOf("month").format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");
      this.reviewBy = [];
      this.pr_generated_by = [];
      this.search = null;
      this.projects = [];
      this.filterStatus = null;
      this.per_page = 25;
      this.projectIds = Array.isArray(this.$route.params.id3) ? this.$route.params.id3 : this.$route.params.id3 ? [this.$route.params.id3] : [];
      this.applyFilter();
    },

    async prRequestList() {
      this.btnLoader = true;
      const input = {
        startDate: this.startDate,
        endDate: this.endDate,
        reviewed_by: this.reviewBy,
        pr_generated_by: this.pr_generated_by,
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        status: this.filterStatus,
        projectId: this.projectIds
      };
   
      this.updateQueryParams();
      const response = await this.getHTTPPostResponse(
        "project/pr-request",
        input,
        false
      );

      if (response && response.status === 200) {
        this.prRequests = response.data.pr_request_list;
        this.totalCount = response.data.count;
      }
      setTimeout(() => {
        this.btnLoader = false;
      }, 200);
    },

    async prRequestStatusCount() {
      this.btnLoader = true;
      const input = {
        startDate: this.startDate,
        endDate: this.endDate,
        reviewed_by: this.reviewBy,
        pr_generated_by: this.pr_generated_by,
        search: this.search,
        status: this.filterStatus,
        projectId:this.projectIds
      };
    
      const response = await this.getHTTPPostResponse(
        "project/pr-request/pr-status-with-count",
        input,
        false
      );

      if (response && response.status === 200) {
        const count = response.data.pr_request_list;
        // Update the cardArray counts
        this.cardArray.forEach((card) => {
          // Match the card key with the response data and update the count
          if (count[card.label] !== undefined) {
            card.count = count[card.label];
          }
        });
      }
      setTimeout(() => {
        this.btnLoader = false;
      }, 200);
    },
    addclass(index = null) {
      if (index) {
        // Get the .b-sidebar-body element
        const sidebarBody = document.querySelector(".tableDesigns");
        // Add the new class
        sidebarBody.classList.add("over-x-unset");
      }
    },
    removeClass() {
      const sidebarBody = document.querySelector(".tableDesigns");
      if (sidebarBody) {
        sidebarBody.classList.remove("over-x-unset");
      }
    },
    applyFilter() {
      this.prRequestList();
      this.prRequestStatusCount();
    },
    
    async getReviewerList() {
      this.btnLoader = true;
      const input = {
        projectId: this.projectIds ? this.projectIds : [],
      };
      const response = await this.getHTTPPostResponse(
        "project/pr-request/project-pr-reviewer-list",
        input,
        false
      );

      if (response && response.status === 200) {
        this.reviewerList = response.data.reviewer_list;
      }
      setTimeout(() => {
        this.btnLoader = false;
      }, 200);
    },
    /* project list */
    async UserProjectList() {
      const input = {
        user_ids: [this.userInfo.id],
      };

      const response = await this.getHTTPPostResponse(
        "project/pr-request/project-pr-project-list",
        input,
        false
      );
      if (response && response.data) {
        const { data } = response;
        this.projectsList = data.project_list;
      }
    },

    updateFilterStatus(status) {
      if (status === "success_ratio") return;
      // Compare the current status
      if (this.filterStatus === status) return;
      if (!this.filterStatus && status === "total") return;
      // Set filterStatus based on the status value
      this.filterStatus = status === "total" ? null : status;
      this.applyFilter();
    },
    /**
     * @description confirmation to delete pr
     * @param {id} client id
     * @returns if conform to delete call delete pr request
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true);
      if (value.isConfirmed === true) {
        this.deletePr(id);
      }
    },

    async deletePr(id) {
      await this.getHTTPDeleteResponse(
        `project/pr-request/delete/${id}`,
        {},
        true
      );

      this.prRequestList();
      this.prRequestStatusCount();
    },
    openPR(link) {
      if (!link.startsWith("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
    },

    /**
     * @description For Edit Client pass data of client to client table form
     * @emits edit-client
     */
    edit(data) {
      eventBus.$emit('edit-pr-request', data)
    },
    /* Reporting And Manage By Users List */
    async getAssigneeList() {
      const input = {
        sort_field: "name",
        sort_order: "asc",
        is_active: 1,
        project_id: this.projectIds,
      };
      if (this.projectIds.length > 0 || this.userInfo.role == "EMP") {
        const response = await this.getHTTPPostResponse(
          "timesheet-user-list",
          input,
          false
        );
        if (response && response.data) {
          const { data } = response;
          this.assigneeList = data.user;
          this.assigneeList.push(this.userInfo);
        }
      } else {
        const response = await this.getHTTPPostResponse(
          "admin/master/user/inactive-user",
          input,
          false
        );
        if (response && response.data) {
          const { data } = response;
          this.assigneeList = data.users;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/tesing.css";
@import "../../../assets/scss/component-css/prrequest.scss";
</style>
