import debounce from "lodash/debounce";
import moment from "moment";

export const peerFeedback = {
  data() {
    return {
      loader: false,
      feedback_status: null,
      totalCount: 0,
      currentPage: this.$route.query.page || 1,
      perPage: this.$route.query.per_page || 100,
      date: `${moment()
        .endOf("month")
        .subtract(1, "quarter")
        .format("YYYY")}-${moment().subtract(1, "quarter").quarter()}`,
      isSchedularModalOpen: false,
      fields: [
        {
          key: "select",
          label: "",
          thStyle: { maxWidth: "25px", minWidth: "25px", paddingRight: "0px" },
        },
        {
          key: "full_name",
          label: "Full name",
          tdStyle: { textAlign: "start !important" },
          thStyle: { textAlign: "start !important" },
        },
        { key: "rating", label: "Rating" },
        { key: "reviewers", label: "Reviewers" },
        {
          key: "status",
          label: "Status",
          thStyle: { textAlign: "center !important" },
        },
        { key: "expire_date", label: "Expire date" },
        {
          key: "action",
          label: "Action",
          tdStyle: { textAlign: "center !important" },
        },
      ],
      users: [],
      startDate: moment().startOf("quarter").format("YYYY-MM-DD"),
      endDate: moment().endOf("quarter").format("YYYY-MM-DD"),
      search: this.$route.query.search || null,
      selectedRows: [],
      employeeList: [],
      modalLoader: false,
      form_expire_date: null,
      configEstimatedStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      eligibleMemberList: [],
      notEligibleMemberList: [],
      initiateMemberList: [],
      isViewModalOpen: false,
      userId: "",
      tooltipContent: `
            <div style="text-align:start;">
              <p><strong>Need to Initiate:</strong> Feedback has not been initiated.</p>
              <p><strong>Initiated:</strong> Feedback has been initiated, but no member has submitted their form rating.</p>
              <p><strong>In Process:</strong> Feedback is initiated, and some members have submitted their form ratings.</p>
              <p><strong>Submitted:</strong> Feedback is initiated, and all members have submitted their form ratings.</p>
              <p><strong>Close:</strong> If the status is close then rating will not update & form will expire.</p>
            </div>
          `,
    };
  },

  computed: {
    feedbackStatusOption() {
      // Sort users list by label
      return [
        { value: "need_to_initiate", label: "Need to Initiate" },
        { value: "initiated", label: "Initiated" },
        { value: "inprogress", label: "Inprogress" },
        { value: "submitted", label: "Submitted" },
        { value: "close", label: "Close" },
      ];
    },
    selectAll: {
      get() {
        return (
          this.selectedRows.length > 0 &&
          this.selectedRows.length === this.users.length
        );
      },
      set(value) {
        this.toggleSelectAll(value);
      },
    },
    debouncedSearch: {
      get() {
        return this.search;
      },
      set: debounce(function (value) {
        this.search = value;
        this.userList();
      }, 750),
    },

    isIndeterminate() {
      return (
        this.selectedRows.length > 0 &&
        (this.selectedRows.length < this.users.length ||
          (this.selectedRows.length > 0 && this.search ? true : false))
      );
    },
    queryData() {
      return {
        page: this.currentPage,
        per_page: this.perPage,
        search: this.search,
        date: this.date,
        status: this.feedback_status,
      };
    },
  },
  watch: {
    currentPage(nv) {
      if (this.$route.query.currentPage !== nv) {
        this.userList();
      }
      this.selectedRows = [];
    },
    date() {
      this.userList();
    },
    perPage(nv) {
      if (this.$route.query.perPage !== nv) {
        if (this.currentPage == 1) {
          this.userList();
        } else {
          this.currentPage = 1;
        }
      }
      this.selectedRows = [];
    },
  },
  mounted() {
    if (this.$route.query) {
      this.setFilterValue();
    } else {
      this.userList();
    }
  },

  methods: {
    // Apply filters and reset the current page
    applyFilter() {
      this.currentPage = 1;
      this.userList();
    },

    // Set filter values based on route query parameters
    setFilterValue() {
      const { query } = this.$route || {};
      if (query) {
        this.perPage = query.per_page || this.perPage;
        this.currentPage = Number(this.$route.query.page) || this.currentPage;
        this.search = query.search || this.search;
        this.feedback_status = query.status || this.status;
        this.date = query.date || this.date;
      }
      this.userList();
    },

    // Calculate the number of reviewed feedback entries
    reviewedFeedbackList(data) {
      return data.filter((form) => form.is_submit == true).length;
    },

    // Get the status variant for styling purposes
    getStatusVariant(status) {
      return status == "close"
        ? "secondary"
        : status == "submitted"
        ? "success"
        : status == "inprogress"
        ? "dark"
        : "primary";
    },

    // Calculate the average rating from the provided data
    avgRating(data) {
      return (
        data.reduce((sum, q) => sum + (q.rating || 0), 0) / data.length
      ).toFixed(2);
    },

    // Select or deselect all users
    toggleSelectAll(value) {
      if (value) {
        this.selectedRows = this.users.map((item) => item.id);
      } else {
        this.selectedRows = [];
      }
    },

    // Clear all selected rows
    clearSelection() {
      this.selectedRows = [];
    },

    // Clear filters and reset to default values
    clearFilter() {
      this.date = `${moment()
        .endOf("month")
        .subtract(1, "quarter")
        .format("YYYY")}-${moment().subtract(1, "quarter").quarter()}`;
      this.per_page = 100;
      this.currentPage = 1;
      this.search = null;
      this.feedback_status = null;
      this.applyFilter();
    },

    // Fetch the user list based on the applied filters
    async userList() {
      this.loader = true;
      const input = {
        start_date: moment(this.date, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date: moment(this.date, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
        page: this.currentPage,
        per_page: this.perPage,
        type: "employee",
        sort_field: "name",
        sort_order: "asc",
        status: this.feedback_status || null,
        search: this.search,
      };
      this.routeQueryUpdate(this.queryData);
      const response = await this.getHTTPPostResponse(
        "feedback-initiative/",
        input,
        false
      );

      if (response && response.status === 200) {
        this.users = response.data.users;
        this.totalCount = response.data.count;
      }
      setTimeout(() => {
        this.loader = false;
      }, 200);
    },

    // Change the status of a specific user's feedback
    async changeStatus(user) {
      if (!user) {
        return false;
      }

      const title = `Are you sure you want to close the rating for ${user.full_name}?`;

      const conformation = await this.conformationAlert(false, title, "Yes");

      if (conformation.isConfirmed == true) {
        this.updateStatus(user);
      }
    },

    // Send a reminder email to a specific user
    async conformationForMail(user) {
      const title = `Are you sure you want to send a reminder email for ${user.full_name} ?`;

      const conformation = await this.conformationAlert(false, title, "Yes");

      if (conformation.isConfirmed == true) {
        this.sendReminderMail(user);
      }
    },

    // Update the feedback initiate status
    async updateStatus(user) {
      let input = {
        ids: user.feedback_initiative?.map((item) => item.id) || [],
      };

      const response = await this.getHTTPPostResponse(
        `feedback-initiative/status-update`,
        input,
        true
      );
      if (response && response.status == 200) {
        this.userList();
      }
    },

    // Send a reminder email for feedback
    async sendReminderMail(user) {
      let input = {
        start_date: moment(this.date, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date: moment(this.date, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
        feedback_receiver_id: user.id,
      };

      const response = await this.getHTTPPostResponse(
        `feedback-initiative/send-remainder-mail`,
        input,
        true
      );
      if (response && response.status == 200) {
        this.userList();
      }
    },
  },
};
