<template>
  <!-- this from use in role and client table -->
  <div class="custom-project-form">
    <validation-observer ref="projectFeedbackObserver">
      <b-sidebar id="addEditRequest" ref="addEditRequest" bg-variant="white" right backdrop shadow class="sidebar-form"
        @hidden="clearData()">
        <h3 class="form-title">
          {{ projectFeedbackLink && projectFeedbackLink.length ? 'Summary' : id ? "Edit" : "Add" }} Feedback
        </h3>
        <div class="line user-underline" />
        <div v-if="projectFeedbackLink && !projectFeedbackLink.length">
          <b-form-group label="Type" label-for="type" class="required-form-filed">
            <validation-provider #default="{ errors }" name="Type" rules="required">
              <v-select v-model="type" hide-details name="type" label="name" :options="types"
                :disabled="id ? true : false" :reduce="(type) => type.value" class="select-size-lg"
                :class="{ 'cursor-default': id }" placeholder="Select Type" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="start_and_end_date">Start And End Date Range<span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="Date" rules="required">
              <b-input-group class="input-group-merge">
                <flat-pickr v-model="date" class="form-control" :class="{ 'cursor-default': id }"
                  :config="startAndEndDate" :disabled="id ? true : false" placeholder="Start and end date range"
                  name="start_and_end_date" />
                <b-input-group-append is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="form_expire_date">Expire Date</label>
            <validation-provider #default="{ errors }" name="Date">
              <b-input-group class="input-group-merge">
                <flat-pickr v-model="form_expire_date" class="form-control" :config="expireDate"
                  placeholder="Feedback expire date" name="form_expire_date" />
                <b-input-group-append is-text>
                  <feather-icon icon="CalendarIcon" />
                </b-input-group-append>
              </b-input-group>
            </validation-provider>
          </b-form-group>

          <b-form-group v-if="type === 'individual'">
            <label for="members">Members <span class="text-danger">*</span></label>
            <validation-provider #default="{ errors }" name="Members" rules="required">
              <v-select id="tags" v-model="members" hide-details multiple name="tags" label="label"
                :options="projectUsers" :reduce="(user) => user.value" class="select-tags-timesheet3"
                :class="{ 'cursor-default': id }" placeholder="Select Any Members" :disabled="id ? true : false"
                :close-on-select="false" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

        </div>
        <div v-else>
          <div class="table role test-plan-table">
            <TableLoader v-if="loader" :fields="fields" />
            <b-row v-else>
              <b-col cols="12">
                <!-- data table start -->
                <b-table responsive :items="projectFeedbackLink" :fields="fields" show-empty
                  class="scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table pr-table">
                  <template #cell(name)="data">
                    <p class="tableData">
                      {{ data.item.type === 'general'
                      ? (data.item.project && data.item.project.name ? data.item.project.name : 'No Project')
                      : (data.item.user && data.item.user.name ?
                      data.item.user.full_name : 'No Receiver') }}
                    </p>
                  </template>
                  <!-- Template for Copy Link column -->
                  <template #cell(copyLink)="data">
                    <div class="copy-link" @click="copyToClipboard(data.item.url)">
                      <feather-icon icon="CopyIcon" size="15" color="#2178fb" class="tableData" />
                    </div>
                  </template>
                </b-table>
                <!-- data table complete -->
                <img v-if="projectFeedbackLink.length === 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data"
                  class="no-data-img" />
                <!-- pagination section -->
              </b-col>
            </b-row>
          </div>
        </div>
        <template #footer>
          <!--form footer start-->
          <div class="form-button d-flex my-2" v-if="projectFeedbackLink && !projectFeedbackLink.length">
            <button class="btn side-bar-btn" :disabled="btnLoader" @click.prevent="validationForm">
              <b-spinner v-if="btnLoader" small class="spinner" />
              {{ id ? "Update" : "Save" }}
            </button>
            <button class="btn side-bar-btn" @click="clearData()">
              Clear
            </button>
          </div>
          <!--form footer end-->
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from 'vue-quill-editor'
import { projectFeedbackFormDetail } from "../../../mixin/project/feedback/form";

export default {
  name: 'ClientTableForm',
  mixins: [projectFeedbackFormDetail],
  components: {
    ValidationObserver,
    ValidationProvider,
    // VSelect,
    flatPickr,
    quillEditor
  },
}
</script>

<style>
@import '../../../assets/scss/component-css/addProjectFeedback.css';
</style>