<template>
  <div>
    <div class="card-filter-section d-flex justify-content-between align-items-center">
      <h3 class="table-title-text mb-0">Peer Feedback</h3>
    </div>
    <div class="viewEdit">
      <!--filter start-->
      <div class="pr-filter">
        <div class="d-flex">
          <p class="filter-title align-self-center">Filters:</p>
          <custom-dropdown ref="givenUser" v-model="feedback_status" :options="feedbackStatusOption" label="Status"
            icon="EditIcon" />
          <div class="align-self-center">
            <OptionDropdown v-model="perPage" />
          </div>
        </div>
        <!--calendar view-->
        <div class="calendar mt-0 justify-content-center align-items-center calendarBg">
          <div id="date-select" class="date-select mr-0">
            <div class="date-dropdown">
              <VueDatePicker v-model="date" type="quarter" no-header />
            </div>
          </div>
        </div>

        <!--calendar view-->
        <div class="d-flex">
          <b-input-group class="input-group-merge search-project"
            style="min-width: 250px; max-width: 250px; width: 250px">
            <b-form-input v-model="debouncedSearch" placeholder="Search by Name" />
            <b-input-group-append is-text>
              <feather-icon v-if="debouncedSearch" icon="XIcon" @click="(debouncedSearch = null), (search = null)" />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <button class="apply-filter filterRedesign mr-1 ml-1" @click="applyFilter()">
            Apply Filter
          </button>
          <button class="apply-filter-clear filterRedesign" @click="clearFilter()">
            Clear
          </button>

          <button class="btn back-button ml-2 p-75 fw-600" type="submit" @click="isSchedularModalOpen = true"
            :disabled="selectedRows.length == 0">
            Feedback Initiate
          </button>
        </div>
      </div>
      <!--filter end-->
    </div>
    <div class="table role test-plan-table status-tooltip">
      <TableLoader v-if="loader" :fields="fields" />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start-->
          <b-table responsive :items="users" :fields="fields" show-empty
            class="scrollable-element tableDesigns test-table defect-table mastertblColor non-project-table pr-table">
            <!-- Select All Checkbox -->
            <template #head(select)="data">
              <b-form-checkbox @change="toggleSelectAll" :indeterminate="isIndeterminate"
                class="custom-control-primary feedback-check"></b-form-checkbox>
            </template>

            <template #head(status)="data">
              <div class="d-flex align-items-center justify-content-center status-header">
                <p class="mb-0">Status</p>
                <feather-icon icon="HelpCircleIcon" size="20" class="ml-1 text-primary"
                  v-b-tooltip.hover.left.html="tooltipContent" aria-label="Feedback status information"
                  ref="customTooltip" />
              </div>
            </template>

            <!-- Row Checkbox -->
            <template #cell(select)="data">
              <b-form-checkbox v-model="selectedRows" :value="data.item.id"
                class="custom-control-primary feedback-check"></b-form-checkbox>
            </template>

            <template #cell(full_name)="data">
              <div class="d-flex align-items-center">
                <div>
                  <b-avatar :text="data.item.full_name | avatarText" size="40" :variant="randomVariant(1, 6)" />
                </div>
                <div class="media-body">
                  <p v-b-tooltip.hover.top class="feedback-user-name" style="margin-left: 5px"
                    :title="data.item.full_name | capitalize">
                    {{ data.item.full_name }}
                  </p>
                </div>
              </div>
            </template>

            <template #cell(rating)="data">
              <div class="min-width text-center">
                <div class="d-flex justify-content-center align-items-center"
                  v-if="data.item.feedback_initiative.length">
                  <h4 class="mb-0 me-1">
                    {{ data.item.feedback_initiative[0].rating || 0 }}
                  </h4>
                  <h3 class="mb-0">/5</h3>
                </div>
                <div class="d-flex justify-content-center align-items-center" v-else>
                  <h4 class="mb-0 me-1">0</h4>
                  <h3 class="mb-0">/5</h3>
                </div>
              </div>
            </template>

            <template #cell(reviewers)="data">
              <div class="min-width text-center">
                <div class="d-flex justify-content-center align-items-center" v-if="
                  data.item.feedback_initiative &&
                  data.item.feedback_initiative.length &&
                  data.item.feedback_initiative[0].feedback_forms
                ">
                  <h4 class="mb-0 me-1">
                    {{
                      reviewedFeedbackList(
                        data.item.feedback_initiative[0].feedback_forms
                      )
                    }}
                  </h4>
                  <h3 class="mb-0">
                    /{{
                      data.item.feedback_initiative[0].feedback_forms.length
                    }}
                  </h3>
                </div>
                <div class="d-flex justify-content-center align-items-center" v-else>
                  <h4 class="mb-0 me-1">-</h4>
                </div>
              </div>
            </template>
            <template #cell(status)="data">
              <div class="min-width">
                <b-badge variant="warning" v-if="!data.item.feedback_initiative.length" class="p-50">
                  need to Initiate
                </b-badge>

                <div v-if="data.item.feedback_initiative.length">
                  <b-badge :variant="getStatusVariant(data.item.feedback_initiative[0].status)
                    " class="p-50">
                    {{ data.item.feedback_initiative[0].status == 'inprogress' ? 'In progress' :
                      data.item.feedback_initiative[0].status }}
                  </b-badge>
                </div>
              </div>
            </template>

            <template #cell(expire_date)="data">
              <div class="min-width">
                <span v-if="
                  data.item.feedback_initiative &&
                  data.item.feedback_initiative.length &&
                  data.item.feedback_initiative[0].form_expire_date
                ">{{
                  data.item.feedback_initiative[0].form_expire_date
                  | DateDDMMYYYYFormat
                }}</span>
                <span v-else>-</span>
              </div>
            </template>

            <template #cell(action)="data">
              <div class="boardDropdown mr-75">
                <b-dropdown toggle-class="text-decoration-none" no-caret boundary="viewport" dropleft text="Drop-Left"
                  v-if="data.item.feedback_initiative.length">
                  <template v-slot:button-content>
                    <button class="edit-del-button">
                      <feather-icon icon="MoreHorizontalIcon" size="21" class="align-left edit-data-delete" />
                    </button>
                  </template>
                  <b-dropdown-item class="edit-delete-item" @click="changeStatus(data.item)"
                    v-if="data.item.feedback_initiative[0].status != 'close'">
                    <span class="edit-data">Close Rating</span>
                  </b-dropdown-item>
                  <b-dropdown-item class="edit-delete-item" @click="conformationForMail(data.item)"
                    v-if="data.item.feedback_initiative[0].status != 'close'">
                    <span class="edit-data">Reminder Mail </span>
                  </b-dropdown-item>
                  <b-dropdown-item class="edit-delete-item" @click="(isViewModalOpen = true), (userId = data.item.id)">
                    <span class="edit-data">View Feedback </span>
                  </b-dropdown-item>
                </b-dropdown>
                <span v-else>-</span>
              </div>
            </template>
          </b-table>
          <!-- pagination section -->
          <Pagination v-if="totalCount > 0 && !loader" :current-page="currentPage" :per-page="perPage"
            :total-count="totalCount" @currentPage="currentPage = $event" />
          <!-- pagination section complete here -->

          <!-- data table complete -->
          <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img" />
          <!-- pagination section -->
        </b-col>
      </b-row>
    </div>

    <!-- feedback initiate component -->
    <feedback-initiate-modal :selectedRows="selectedRows" :date="date" :isOpen="isSchedularModalOpen"
      @clearSelected="selectedRows = []" @reload="userList()" @modalClose="isSchedularModalOpen = $event" />

    <!-- feedback view component -->
    <feedback-view-modal :is-open="isViewModalOpen" :userId="userId" :date="date"
      @modalClose="isViewModalOpen = $event" />
  </div>
</template>

<script>
import customDropdown from "@/components/dropdown/customDropdown.vue";
import OptionDropdown from "../../../components/optiondropdown/OptionDropdown.vue";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import TableLoader from "@/components/loaders/table-loading.vue";
import FeedbackInitiateModal from "./FeedbackInitiateModal.vue";
import FeedbackViewModal from "./FeedbackViewModal.vue";
import { peerFeedback } from "../../../mixin/peerFeedback/index";

export default {
  name: "PrRequest",
  mixins: [peerFeedback],
  components: {
    customDropdown,
    OptionDropdown,
    VueDatePicker,
    TableLoader,
    FeedbackInitiateModal,
    FeedbackViewModal,
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/tesing.css";
@import "../../../assets/scss/component-css/prrequest.scss";
@import "../../../assets/scss/component-css/peerfeedback.scss";
</style>
