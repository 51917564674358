<template>
  <div>
    <b-modal
      id="feedbackInitiate"
      v-model="isSchedularModalOpen"
      content-class="schedule-model"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      size="lg"
    >
      <template #modal-title>
        <span class="heading">Feedback Initiate</span><br />
      </template>
      <div v-if="modalLoader" class="time-log-loader">
        <div class="time-loader-icon">
          <b-spinner label="Loading..." variant="primary" />
        </div>
      </div>
      <div
        class="d-flex bg-white flex-wrap count-card-shadow boarder-radius-20 peer-feedback"
      >
        <div
          v-for="(card, index) in cardArray"
          :key="index"
          class="box-card-design cursor-pointer"
          :class="card.class"
          @click="status = card.value"
        >
          <div>{{ card.label }}</div>
          <p class="count cursor-pointer">
            {{ card.count }}
          </p>
        </div>
      </div>

      <!-- Accordion Component -->
      <div class="user-list boarder-radius-20">
        <app-collapse accordion type="margin">
          <transition-group name="fade" tag="div">
            <app-collapse-item
              v-for="(item, index) in employees"
              :key="index"
              :title="''"
              class="employee-name"
            >
              <!-- Custom Header -->
              <template #header>
                <div
                  class="custom-header d-flex justify-content-between align-items-center w-100"
                >
                  <div>
                    <span class="header-name fw-600">{{
                      item.user.full_name
                    }}</span>

                    <b-badge
                      :variant="
                        item.projectPartners && item.projectPartners.length > 0
                          ? item.user.feedback_initiative_count > 0
                            ? ' purple-color ml-1'
                            : 'light-success ml-1'
                          : 'light-warning ml-1'
                      "
                    >
                      {{
                        item.projectPartners && item.projectPartners.length > 0
                          ? item.user.feedback_initiative_count > 0
                            ? "Initiated"
                            : "Eligible"
                          : "Not-Eligible"
                      }}
                    </b-badge>
                  </div>
                  <div>
                    <b-badge variant="light-primary">
                      {{ item.projectPartners.length + " Reviewer" }}
                    </b-badge>
                  </div>
                </div>
              </template>

              <!-- Content with Smooth Animation -->
              <transition name="smooth-collapse">
                <!-- Content -->
                <div v-if="item.projectPartners.length > 0">
                  <div
                    class="text-dark fw-600 employee-list"
                    v-for="(projectUser, index) in item.projectPartners"
                    :key="index"
                  >
                    <div
                      class="d-flex justify-content-between align-items-center mt-1"
                    >
                      <span>{{
                        projectUser &&
                        projectUser.employee &&
                        projectUser.employee.full_name
                      }}</span>
                      <div>
                        <b-badge
                          v-for="project in projectUser.projects"
                          :key="project.id"
                          variant="light-danger mr-1"
                        >
                          {{ project.name }}
                        </b-badge>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-dark fw-600 employee-list" v-else>
                  No project partner during this timeline
                </div>
              </transition>
            </app-collapse-item>
          </transition-group>
        </app-collapse>      
          <div class="text-dark fw-600 m-1" v-if="!employees.length">
            There are no members present in this list.
          </div>
      </div>

      <b-row class="d-flex justify-content-center boarder-radius-20">
        <b-col cols="12" md="12" class="mt-1">
          <b-form-group>
            <label for="actual_start_date">Expire Form Date</label>

            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="form_expire_date"
                :config="configEstimatedStartDate"
                class="form-control"
                name="assignment_start_date"
                placeholder="Select Expire Form Date"
              />
              <b-input-group-append is-text>
                <feather-icon icon="CalendarIcon" size="20" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-center">
        <button
          class="btn back-button mt-50 p-75 fw-600"
          type="submit"
          @click="initiateFeedbackForm()"
          :disabled="modalLoader || isDisable "
        >
          Initiate Feedback For Eligible Member
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import TableLoader from "@/components/loaders/table-loading.vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { feedbackInitiateDetail } from "../../../mixin/peerFeedback/initiateModal";

export default {
  name: "FeedbackInitiateDetail",
  components: {
    AppCollapse,
    AppCollapseItem,
    TableLoader,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [feedbackInitiateDetail],
};
</script>
