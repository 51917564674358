<template>
  <div>
    <b-modal id="feedbackView" v-model="isViewModalOpen" content-class="schedule-model" centered hide-footer
      :no-close-on-backdrop="true" size="lg">

      <template #modal-title>
        <span class="heading"> {{ feedbackReceiverDetail && feedbackReceiverDetail.full_name }} Feedback ({{ dateFormat
          }}) </span><br>
      </template>
      <div v-if="loader" class="time-log-loader">
        <div class="time-loader-icon">
          <b-spinner label="Loading..." variant="primary" />
        </div>
      </div>
      <div class="d-flex  align-items-center justify-content-between mt-2" v-if="feedbackReceiverDetail">
        <div class="d-flex align-items-center">
          <div>
            <b-avatar :text="feedbackReceiverDetail.full_name | avatarText" size="40" :variant="'dark'" />
          </div>
          <div class="media-body">
            <p v-b-tooltip.hover.top class="feedback-user-name text-dark" style="margin-left: 5px"
              :title="feedbackReceiverDetail.full_name | capitalize">
              {{ feedbackReceiverDetail.full_name }}
            </p>
          </div>
        </div>
        <div class="average-rating">
          <div class="media-body">
            <p class="feedback-user-name text-dark" style="margin-left: 5px">
              Average rating
            </p>
          </div>
          <div>
            <b-avatar :text="`${(rating)}`" size="40" :variant="'warning'" />
          </div>
        </div>
        <div>
          <b-badge :variant="getStatusVariant(status)" class="p-50">
            <span class="fw-600 text-capitalize"> {{ status == 'inprogress' ? 'In Progress' : status}}</span>
          </b-badge>
        </div>
      </div>

      <p class="mt-2 fw-600 text-dark font-size-16" v-show="false"> Feedback Description</p>
      <div class="feedback-view mt-1">

        <b-row class="bg-light mt-1 p-1 feedback-row d-flex justify-content-center"
          v-for="(feedback, index) in feedbackList" :key="index">
          <b-col cols="10" md="10" class="d-flex align-items-center">
            <div>
              <b-avatar :text="feedback.feedback_provider.full_name | avatarText" size="40"
                :variant="randomVariant(1, 6)" />
            </div>
            <div class="media-body">
              <p v-b-tooltip.hover.top class="feedback-user-name text-dark feedback-provider-name"
                :title=" feedback.feedback_provider.full_name | capitalize">
                {{ feedback.feedback_provider.full_name }}
              </p>
            </div>
          </b-col>
          <b-col cols="2" md="2" class="d-flex align-items-center">
            <div>
              <p v-b-tooltip.hover.top class="feedback-user-name text-dark feedback-provider-name"
                :title="feedback.rating">
                {{ feedback.rating }}
              </p>
            </div>
          </b-col>

          <b-col cols="12" class="ml-1" md="12">
            <p class="mt-1 text-dark feedback-description">
              <v-clamp autoresize :max-lines="3" ellipsis="..." location="end" :style="{ whiteSpace: 'pre-line' }"
                class="cursorDefault">
                {{ feedback.description ? feedback.description : 'No feedback has been received at this time.' }}
                <template #after="{ toggle, clamped, expanded }">
                  <button v-if="clamped == true || expanded == true" class="see-more-btn" @click="toggle">
                    {{ clamped == true ? "see more" : "see less" }}
                  </button>
                </template>
              </v-clamp>
            </p>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import VClamp from 'vue-clamp'
import { FeedbackView } from "../../../mixin/peerFeedback/view.js";

export default {
  name: "FeedbackView",
  mixins: [FeedbackView],
  components: {
    VClamp,
  },
};
</script>