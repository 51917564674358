import * as moment from "moment/moment";
import debounce from "lodash/debounce";
import { eventBus } from "@/main";

export const projectFeedbackFormDetail = {
  data() {
    return {
      id: null,
      loader: false,
      btnLoader: false,
      members: [],
      projectFeedbackLink: [],
      users: [],
      type: "general",
      form_expire_date: null,
      date: `${moment()
        .startOf("month")
        .format("YYYY-MM-DD")} to ${moment().format("YYYY-MM-DD")}`,
      types: [
        { name: "General", value: "general" },
        { name: "Individual", value: "individual" },
      ],
      expireDate: {
        dateFormat: "Y/m/d",
        minDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      },
      startAndEndDate: {
        dateFormat: "Y/m/d",
        mode: "range",
      },
      assigneeList: [],
      fields: [
        {
          key: "name",
          label: "Name",
          tdStyle: {
            textAlign: "center !important",
          },
        },
        {
          key: "copyLink",
          label: "Copy Link",
          tdStyle: {
            textAlign: "center !important",
          },
        },
      ],
    };
  },
  props: {
    projectUsers: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  mounted() {
    eventBus.$on("edit-feedback-request", (data) => {
      if (data) {
        this.id = data.id;
        this.date = data.start_date + " to " + data.end_date;
        this.type = data.type;
        this.form_expire_date =data.form_expire_date;
        this.members = [data.user_id];
        this.$root.$emit("bv::toggle::collapse", "addEditRequest");
      }
    });
  },
  methods: {
    /**
     * check the form validation
     * @returns if success true then call addProjectFeedback Method
     */
    validationForm() {
      this.$refs.projectFeedbackObserver.validate().then((success) => {
        if (success && this.date && this.type) {
          if (this.type != "general" && !this.members.length) return;
          if (this.id && !this.form_expire_date) return;
          this.addProjectFeedback();
        }
      });
    },
    /**
     * Add & Update the clientTableForm details & call client listing in clienttable
     * @emits clientList
     * @returns client detail add & update
     */
    async addProjectFeedback() {
      const dateArr = this.date ? this.date.split(" to ") : [];

      this.btnLoader = true;
      let input = {
        id: this.id,
        type: this.type,
        start_date: dateArr[0],
        end_date: dateArr[1] ? dateArr[1] : dateArr[0],
        user_ids: this.members,
        project_id: this.$route.params.id3,
        form_expire_date: this.form_expire_date,
      };
      let url = this.id
        ? `project-feedback/project-feedback-form/${this.id}`
        : "project-feedback/project-feedback-form";
      const response = await this.getHTTPPostResponse(url, input, true);
      if (response && response.status === 200) {
        this.projectFeedbackLink = response.data.project_feedbacks;
        if (this.id) {
          this.$root.$emit("bv::toggle::collapse", "addEditRequest");
        }
        this.$emit("reloadList", true);
      }
      setTimeout(() => {
        this.btnLoader = false;
      }, 200);
    },

    /**
     *  Clear form details
     */
    clearData() {
      this.id = null;
      this.type = "general";
      this.members = [];
      this.form_expire_date = null;
      this.date = `${moment()
        .startOf("month")
        .format("YYYY-MM-DD")} to ${moment().format("YYYY-MM-DD")}`;
      this.projectFeedbackLink = [];
      this.$refs.projectFeedbackObserver.reset();
    },
  },
};
