import * as moment from "moment/moment";

export const feedbackInitiateDetail = {
  data() {
    return {
      loader: false,
      isSchedularModalOpen: false,
      modalLoader: false,
      form_expire_date: null,
      configEstimatedStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      employeeList: [],
      eligibleMemberList: [],
      notEligibleMemberList: [],
      initiateMemberList: [],
      status: null,
    };
  },
  props: {
    selectedRows: {
      type: Array,
      required: true,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    date: {
      type: String,
      required: true,
      default: "",
    },
  },
  computed: {
    cardArray() {
      return [
        {
          key: "total",
          label: "Selected Members",
          class: "not-a-issuecard",
          value: null,
          count: this.selectedRows.length,
        },
        {
          key: "open",
          label: "Eligible Members",
          class: "resolved",
          value: "eligible",
          count: this.eligibleMemberList.length,
        },
        {
          key: "rejected",
          label: "Initiated Members",
          class: "total",
          value: "initiated",
          count: this.initiateMemberList.length,
        },
        {
          key: "rejected",
          label: "Non-Eligible Members",
          class: "warning",
          value: "notEligible",
          count: this.notEligibleMemberList.length,
        },
      ];
    },
    employees() {
      if (this.status === "eligible") {
        return this.eligibleMemberList;
      } else if (this.status === "initiated") {
        return this.initiateMemberList;
      } else if (this.status === "notEligible") {
        return this.notEligibleMemberList;
      } else {
        return this.employeeList;
      }
    },
    isDisable() {
      return this.eligibleMemberList && this.eligibleMemberList.length
        ? this.eligibleMemberList.every(
            (item) => item.projectPartners.length === 0
          )
        : true;
    },
  },
  watch: {
    isOpen(nv) {
      this.isSchedularModalOpen = nv;
    },

    isSchedularModalOpen(nv) {
      if (nv) {
        this.employeeWithProjectPartner();
      } else {
        this.eligibleMemberList = [];
        this.notEligibleMemberList = [];
        this.$emit("modalClose", nv);
      }
    },
    selectedRows() {
      this.status = null;
    },
  },
  methods: {
    async employeeWithProjectPartner() {
      this.modalLoader = true;
      const input = {
        start_date: moment(this.date, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date: moment(this.date, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
        user_ids: this.selectedRows,
      };

      const response = await this.getHTTPPostResponse(
        "feedback-initiative/employee-with-project-partner",
        input,
        false
      );
      if (response && response.status === 200) {
        this.employeeList = response.data.users;
        this.eligibleMemberList = response.data.users.filter(
          (userDetail) =>
            userDetail.projectPartners.length > 0 &&
            !userDetail.user.feedback_initiative_count > 0
        );

        this.notEligibleMemberList = response.data.users.filter(
          (userDetail) => userDetail.projectPartners.length === 0
        );

        this.initiateMemberList = response.data.users.filter(
          (userDetail) => userDetail.user.feedback_initiative_count > 0
        );
      }
      setTimeout(() => {
        this.modalLoader = false;
      }, 200);
    },
    async initiateFeedbackForm() {
      this.modalLoader = true;
      const input = {
        start_date: moment(this.date, "YYYY-Q")
          .startOf("quarter")
          .format("YYYY-MM-DD"),
        end_date: moment(this.date, "YYYY-Q")
          .endOf("quarter")
          .format("YYYY-MM-DD"),
        form_expire_date: this.form_expire_date,
        feedback_receiver_ids: this.eligibleMemberList.map(
          (user) => user.user.id
        ),
      };

      const response = await this.getHTTPPostResponse(
        "feedback-initiative/initiate-feedback-form",
        input,
        true
      );

      if (response && response.status === 200) {
        setTimeout(() => {
          this.eligibleMemberList = [];
          this.notEligibleMemberList = [];
          this.isSchedularModalOpen = false;
          this.$emit("reload", true);
          this.$emit("clearSelected", true);
        }, 1000);
      }
      setTimeout(() => {
        this.modalLoader = false;
      }, 1000);
    },
  },
};
