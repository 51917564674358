<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="questionObserver">
      <b-sidebar id="feedbackQuestion" bg-variant="white" right backdrop shadow class="sidebar-form"
        @hidden="sidebarToggle">
        <!--  form start -->

        <h3 class="form-title">{{ id ? "Edit" : "Add New" }} Question <feather-icon icon="HelpCircleIcon" size="100"
            class="ml-1 text-primary" v-b-tooltip.hover.top.html="'% this member % replace with Employee First name'
              " aria-label="Feedback status information" ref="customTooltip" /></h3>
        <div class="line" style="width: 200px" />

        <div class="b-sidebar-header">
          <!-- Name input -->
          <b-row>
            <b-col cols="12" md="12">
              <!-- Description input -->
              <b-form-group>
                <label label-for="Question">Question <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Question" mode="passive" rules="required">
                  <b-form-textarea id="Question" v-model="question" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Question & % this member % replace with Employee First name" name="Question" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- project detail complete here -->
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <!-- Description input -->
              <b-form-group>
                <label label-for="Description">Description <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Description" mode="passive" rules="required">
                  <b-form-textarea id="Description" v-model="description" :state="errors.length > 0 ? false : null"
                    placeholder="Enter Description" name="Question" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- project detail complete here -->
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12">
              <b-form-group label="Type" label-for="work_type">
                <validation-provider #default="{ errors }" name="Type" mode="passive" rules="required">
                  <v-select v-model="type" name="work_type" label="name" :options="feedbackTypeList"
                    :reduce="(option) => option.value" class="select-size-lg" placeholder="Select question for" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button class="btn side-bar-btn" type="submit" :disabled="btnLoader" @click.prevent="validationForm">
              <b-spinner v-if="btnLoader" small class="spinner" />
              {{ id ? "Update" : "Add" }} Question
            </button>

            <button class="btn side-bar-btn" @click="clearData()">
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { QuestionForm } from "../../../mixin/masterSetting/feedbackQuestion/form";

export default {
  name: 'FeedbackQuestionForm',
  mixins: [QuestionForm],
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
  },
}
</script>
