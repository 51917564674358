<template>
  <!-- this form is use in project table  -->
  <div class="custom-project-form">
    <validation-observer ref="projectObserver">
      <b-sidebar id="addEditProject" bg-variant="white" right backdrop shadow class="sidebar-form"
        @hidden="sidebarToggle" @shown="removeBodyScroll()">
        <!--  form start -->

        <h3 class="form-title">{{ id ? "Edit" : "Add New" }} Project</h3>
        <div class="line" style="width: 200px" />

        <div class="b-sidebar-header">
          <!-- Name input -->
          <b-row>
            <b-col cols="12" md="6">
              <!-- <p class="new-smmarydetail-title mb-50">NAME <span class="text-danger">*</span></p>  -->
              <label>NAME <span class="text-danger">*</span></label>
              <validation-provider #default="{ errors }" name="Name" mode="passive" rules="required">
                <b-form-input id="project name" v-model="name" :state="errors.length > 0 ? false : null"
                  placeholder="Name" name="project name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <label>PROJECT CODE <span class="text-danger">*</span></label>
              <validation-provider #default="{ errors }" name="Code" mode="passive" rules="required|max:5">
                <b-form-input id="projectcode" v-model="code" type="text" name="projectcode"
                  placeholder="Project Code" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="12">
              <!-- Description input -->
              <b-form-group>
                <label label-for="description">Detail <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Description" mode="passive" rules="required|max:500">
                  <b-form-textarea id="description" v-model="description" :state="errors.length > 0 ? false : null"
                    placeholder="Description" name="Description" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- project detail complete here -->
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Work Type" label-for="work_type">
                <validation-provider #default="{ errors }" name="Work type" mode="passive" rules="required">
                  <v-select v-model="workType" name="work_type" label="name" :options="workTypeList"
                    :reduce="(option) => option.value" class="select-size-lg" placeholder="Select work type"
                    :disabled="true" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <!-- project color -->
              <label>COLOR <span class="text-danger">*</span></label>
              <validation-provider #default="{ errors }" name="Project color" mode="passive" rules="required">
                <b-input-group class="color-picker-input">
                  <b-form-input id="projectcolor" v-model="projectColor" type="text" name="projectcolor"
                    placeholder="Select any color" />
                  <b-input-group-append is-text>
                    <input v-model="projectColor" type="color" name="projectcolor" class="color-picker" />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row v-if="workType === 'productive'">
            <b-col cols="12" class="light_line w-full" />
            <b-col cols="12" md="6">
              <label>PROJECT TYPE <span class="text-danger">*</span></label>
              <validation-provider #default="{ errors }" name="Project type" mode="passive" rules="required">
                <v-select id="projecttype" v-model="type" hide-details name="projecttype" label="label"
                  :options="projectTypeList" :reduce="(type) => type.value" class="select-size-lg"
                  placeholder="Select type" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col cols="12" md="6">
              <label>STATUS <span class="text-danger">*</span></label>
              <validation-provider #default="{ errors }" name="Project status" mode="passive" rules="required">
                <v-select id="status" v-model="status" hide-details name="status" label="label"
                  :options="projectStatusList" :reduce="(status) => status.value" class="select-size-lg"
                  placeholder="Select status" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated_start_date">Estimated start date</label>
                <validation-provider #default="{ errors }" name="Estimated start date" rules="">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="estimated_start_date" class="form-control" :config="configEstimatedStartDate"
                      placeholder="Estimated start date" name="estimated_start_date"
                      @on-change="onEstimatedStartDateChange" @on-open="addClass()" @on-close="removeClass()" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" /></b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="estimated end date">Estimated end date</label>
                <validation-provider #default="{ errors }" name="Estimated end date" rules="">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="estimated_end_date" class="form-control" :config="configEstimatedEndDate"
                      placeholder="Estimated end date" name="estimated_end_date" @on-change="onEstimatedEndDateChange"
                      @on-open="addClass()" @on-close="removeClass()" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="actual_start_date">Actual start date</label>
                <validation-provider #default="{ errors }" name="Actual start date" rules="">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="actual_start_date" class="form-control" :config="configActualStartDate"
                      placeholder="Actual start date" name="actual_start_date" @on-change="onActualStartDateChange"
                      @on-open="addClass()" @on-close="removeClass()" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col xl="6" md="12">
              <b-form-group>
                <label for="actual_end_date">Actual end date</label>
                <validation-provider #default="{ errors }" name="Actual end date" rules="">
                  <b-input-group class="input-group-merge">
                    <flat-pickr v-model="actual_end_date" class="form-control" :config="configActualEndDate"
                      placeholder="Actual end date" name="actual_end_date" @on-change="onActualEndDateChange"
                      @on-open="addClass()" @on-close="removeClass()" />
                    <b-input-group-append is-text>
                      <feather-icon icon="CalendarIcon" />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="workType === 'productive'">
            <b-col cols="12" class="light_line w-full" />
            <b-col cols="12" md="6">
              <validation-observer ref="clientObserver">
                <div class="primary-skill-section">
                  <span class="primary-skill-section-span"><feather-icon v-if="clientShow === false" icon="PlusIcon"
                      size="16" @click="clientShow = true" />
                    <feather-icon v-if="clientShow === true" icon="XIcon" size="16"
                      @click="clientShow = false" /></span>
                  <label>CLIENT NAME <span class="text-danger">*</span></label>
                  <validation-provider #default="{ errors }" name="Client" rules="required">
                    <v-select v-if="clientShow === false" id="client name" v-model="client_id" hide-details
                      name="client_id" label="name" :options="clientListComputed" :reduce="(client) => client.id"
                      class="select-size-lg" placeholder="Select Client" />
                    <small v-if="clientShow === false" class="text-danger">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </div>
                <!-- Client Name  -->
                <b-form-group v-if="clientShow === true">
                  <validation-provider #default="{ errors }" name="Client" rules="required">
                    <b-form-input id="Client Name" v-model="clientName" :state="errors.length > 0 ? false : null"
                      placeholder="Client Name" name="Client Name" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group v-if="clientShow === true">
                  <validation-provider #default="{ errors }" name="Country" rules="required">
                    <v-select id="country name" v-model="country" hide-details name="country" label="name"
                      :options="countries" :reduce="(country) => country.id" class="select-size-lg"
                      placeholder="Select Country" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="form-button-new d-flex mt-1">
                  <button v-if="clientShow === true" class="btn side-bar-btn" type="submit" :disabled="btnLoader"
                    @click.prevent="
                      validateForm('clientObserver', 'saveClientDtl')
                      ">
                    <b-spinner v-if="btnLoader" small class="spinner" />
                    {{ "Add Client" }}
                  </button>
                </div>
              </validation-observer>
            </b-col>

            <!-- Project scope  -->
            <b-col cols="12" md="6">
              <validation-observer ref="projectScopeObserver">
                <div class="primary-skill-section">
                  <span class="primary-skill-section-span"><feather-icon v-if="projectScopeShow === false"
                      icon="PlusIcon" size="16" @click="projectScopeShow = true" />
                    <feather-icon v-if="projectScopeShow === true" icon="XIcon" size="16"
                      @click="projectScopeShow = false" /></span>
                  <label>PROJECT SCOPE <span class="text-danger">*</span></label>
                  <validation-provider #default="{ errors }" name="Project Scope" rules="required">
                    <v-select v-if="projectScopeShow === false" id="project_scope_id" v-model="project_scope_id"
                      hide-details name="project_scope_id" label="name" :options="projectScopes"
                      :reduce="(scope) => scope.id" class="select-size-lg primary-skill-select"
                      placeholder="Select project scope" :close-on-select="false" multiple />
                    <small v-if="projectScopeShow === false" class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
                <!-- Project scope input -->
                <b-form-group v-if="projectScopeShow === true">
                  <validation-provider #default="{ errors }" name="Project Scope" rules="required">
                    <b-form-input id="Project scope" v-model="projectScopeName"
                      :state="errors.length > 0 ? false : null" placeholder="Project scope" name="Project scope" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!--Project Scope Color start-->
                <b-form-group v-if="projectScopeShow === true">
                  <validation-provider #default="{ errors }" name="Color" rules="required">
                    <b-input-group class="color-picker-input">
                      <b-form-input id="scopeColor" v-model="scopeColor" type="text"
                        :state="errors.length > 0 ? false : null" name="scopeColor" placeholder="Select any color" />
                      <b-input-group-append is-text>
                        <input v-model="scopeColor" type="color" name="scopecolor" class="color-picker" />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <div class="form-button-new d-flex mt-1">
                  <button v-if="projectScopeShow === true" class="btn side-bar-btn" type="submit" :disabled="btnLoader"
                    @click.prevent="
                      validateForm(
                        'projectScopeObserver',
                        'saveProjectScopeDtl'
                      )
                      ">
                    <b-spinner v-if="btnLoader" small class="spinner" />
                    {{ "Add Scope" }}
                  </button>
                </div>
                <!--Project Scope color end-->
              </validation-observer>
            </b-col>

            <b-col cols="12" md="6">
              <b-form-group label="time estimate" label-for="Time Estimate">
                <validation-provider #default="{ errors }" name="Time estimate" rules="min_value:0|max_value:10000">
                  <b-input-group id="timeestimate" append="Hours" class="input-group-merge">
                    <b-form-input v-model="time_estimate" name="timeestimate" type="number"
                      placeholder="Time Estimate" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="type === 'fix_cost'" cols="12" md="6">
              <b-form-group label-for="estimate cost">
                <label for="actual_start_date">estimate cost <span class="text-danger">*</span></label>
                <validation-provider #default="{ errors }" name="Estimate cost" rules="required|min_value:0">
                  <b-input-group id="estimate cost" append="euro" class="input-group-merge">
                    <b-form-input v-model="budget_estimated" name="estimate cost" type="number"
                      placeholder="Estimate Cost" />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="userInfo && userInfo.role != 'SA'" cols="12" class="light_line w-full" />
            <b-col v-if="userInfo && userInfo.role != 'SA'" cols="12" md="12">
              <b-form-group class="assignmentTitle">
                <h3>Add Assignment</h3>
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label for="actual_start_date">Assignment date
                        <span class="text-danger">*</span></label>
                      <validation-provider #default="{ errors }" name="Assignment start date" rules="required">
                        <b-input-group class="input-group-merge">
                          <flat-pickr v-model="assignment_start_date" :config="configAssignmentStartDate"
                            class="form-control" name="assignment_start_date" placeholder="Select Month"
                            @on-change="setConfig('config')" @on-open="addClass()" @on-close="removeClass()" />
                          <b-input-group-append is-text>
                            <feather-icon icon="CalendarIcon" size="20" />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Time Plan" label-for="Time Plan">
                      <validation-provider #default="{ errors }" name="Time plan" rules="min_value:0|max_value:200">
                        <b-input-group id="time_plan" class="input-group-merge" append="Hours">
                          <b-form-input v-model="time_plan" name="time_plan" type="number" placeholder="Assign Hours"
                            @input="setConfig('setassignment')" />
                        </b-input-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group>
                      <label label-for="assignment">Percentage <span class="text-danger">*</span></label>
                      <validation-provider #default="{ errors }" name="Assignment"
                        rules="required|min_value:0|max_value:100">
                        <b-input-group class="input-group-merge" append="%">
                          <b-form-input id="assignment" v-model="assignment" type="number"
                            placeholder="Assign percentage" :max="3" @input="setConfig(index)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <!-- TODO: add is_billable field -->
                    <!-- is billable start -->
                    <div>
                      <b-form-group label="billable" label-for="assignment">
                        <div class="align-items-center text-center w-25">
                          <div :class="is_billable ? 'billable' : 'nonBillable'">
                            <span @click="is_billable = !is_billable">$</span>
                          </div>
                        </div>
                      </b-form-group>
                    </div>
                    <!-- is billable end -->
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- billing infromation comeplete here -->
        </div>
        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button class="btn side-bar-btn" type="submit" :disabled="btnLoader" @click.prevent="
              validateForm('projectObserver', 'saveProjectDtl', false)
              ">
              <b-spinner v-if="btnLoader" small class="spinner" />
              {{ id ? "Update" : "Add" }} Project
            </button>
            <button class="btn side-bar-btn" type="submit" :disabled="btnLoader" @click.prevent="
              validateForm('projectObserver', 'saveProjectDtl', true)
              ">
              <b-spinner v-if="btnLoader" small class="spinner" />
              Add & Open Project
            </button>
            <button class="btn side-bar-btn" @click="clearData()">Clear</button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import { projectForm } from "./../../../mixin/projectForm/index";

export default {
  name: "ProjectTableForm",
  mixins: [projectForm],
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    vSelect,
  },
};
</script>

<style lang="scss">
@import "../../../assets/scss/component-css/project-form.scss";
</style>
