import debounce from 'lodash/debounce'
import * as moment from 'moment/moment'

export const bugReportDetail = {
    data() {
        return {
          totalCount: 0,
          currentPage: 1,
          perPage: 100,
          manage_by: null,
          reporting_to: null,
          reporting_to_users: [],
          manage_by_users: [],
          loader: false,
          search: null,
          startDate: `${moment().format("YYYY")}-${moment().quarter()}`,
          fields: [
            {
              key: "full_name",
              label: "Name",
            },
            {
              key: "reporting_to",
              label: "Reporting To",
              thStyle: { align: "start !important" },
              thStyle: { align: "start !important" },
            },
            {
              key: "manage_by",
              label: "Manage By",
              thStyle: { align: "start !important" },
              thStyle: { align: "start !important" },
            },
            {
              key: "bug_count",
              label: "Bug Count",
              thClass: "text-center",
              tdClass: "text-center",
            },
            {
              key: "improvement_count",
              label: "Improvement Count",
              thClass: "text-center",
              tdClass: "text-center",
            },
          ],
          items: [],
        };
    },
    computed: {
        debouncedSearch: {
            get() {
                return this.search
            },
            set: debounce(function (value) {
                this.search = value
                this.bugReport()
            }, 750),
        },
    },
    watch: {
        currentPage: {
            handler(value) {
                if (value) {
                    this.bugReport()
                }
            },
        },
        perPage() {
            if (this.currentPage == 1) {
                this.bugReport()
            } else {
                this.currentPage = 1
            }
        },
        startDate(nv) {
            if (nv) {
                setTimeout(() => {
                    this.bugReport()
                }, 200)
            }
        },
    },
    mounted() {
        if (this.userInfo.role != 'SA') {
            this.manage_by = this.userInfo.id
        }
        this.filterFunctionCallFromMixin()
        this.bugReport()
    },
    methods: {
        async filterFunctionCallFromMixin() {
            this.manage_by_users = await this.manageByUserList()
            this.reporting_to_users = await this.reportingtoUserList()
        },

        async bugReport() {
            this.loader = true
            const input = {
                page: this.currentPage,
                per_page: this.perPage,
                sort_field: 'name',
                sort_order: 'asc',
                search: this.search,
                start_date: moment(this.startDate, 'YYYY-Q').startOf('quarter').format('YYYY-MM-DD'),
                end_date: moment(this.startDate, 'YYYY-Q').endOf('quarter').format('YYYY-MM-DD'),
                manage_by: this.manage_by ? this.manage_by : null,
                reporting_to: this.reporting_to ? this.reporting_to : null,
            }
            const response = await this.getHTTPPostResponse('admin/master/reports/bug-report', input, false)
            if (response && response.data) {
                const { data } = response
                this.items = data.bug_report
                this.totalCount = data.count
            }
            this.loader = false
        },
        clearFilter() {
            this.manage_by = null
            this.reporting_to = null
            this.perPage = 100
            this.search = null
            this.startDate = `${moment().format("YYYY")}-${moment().quarter()}`
            this.bugReport()
        },
    },
}