<template>
  <div>

    <div class="report-view task-time-sheet-report" v-if="loader">
      <div class="report-loader-screen">
        <div class="d-flex align-items-center justify-content-center h-100 overdlow-sheet">
          <img src="../../../../public/loader.gif" alt="">
        </div>
      </div>
    </div>
    <div v-else-if="!feedbackFormStatus">
      <b-card img-alt="Profile Cover Photo" img-top class="mb-0 card ">
        <b-row class="feedback-peer">
          <!-- user information card start-->
          <b-col cols="12" md="4" lg="3" v-if="type !== 'client'">
            <div class="text-center">
              <div class="profile-image p-0 ">
                <b-avatar size="114" :variant="randomVariant(1, 6)" :src="feedbackReceiver.picture"
                  :text="feedbackReceiver.full_name | avatarText"
                  v-if="feedbackReceiver && (feedbackReceiver.picture || feedbackReceiver.full_name)" />
                <b-avatar size="114" :variant="randomVariant(1, 6)" v-else />
              </div>
            </div>
            <h3 class="mt-2 mb-2 text-center text-common-color">{{ feedbackReceiver && feedbackReceiver.full_name }}
            </h3>
            <hr class="mb-2">
            <div class="d-flex justify-content-between align-items-center ">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  You worked with {{ feedbackReceiver && feedbackReceiver.full_name }} on following projects between {{
                  FormattedDateRange }}
                </h6>
                <!-- <h3 class="mb-0"> -->
                <div class=" d-flex justify-content-start mt-2 display-wrap">
                  <b-badge class="profile-badge ml-1 mt-1" variant="light-primary" v-for="project in projects"
                    :key="project.id">
                    {{ project.name }}
                  </b-badge>
                </div>
              </div>
            </div>
          </b-col>
          <!-- user information card end-->

          <!-- client feedback -->
          <b-col cols="12" md="4" lg="3" v-else>

            <div class="text-center">
              <div class="profile-image p-0 ">

                <b-avatar size="114" :variant="randomVariant(1, 6)" :text="feedbackReceiver.full_name | avatarText"
                  v-if="feedbackReceiver && feedbackReceiver.full_name" />
                <b-avatar size="114" :variant="randomVariant(1, 6)" :text="projectDetail.name | avatarText"
                  v-else-if="projectDetail && projectDetail.name" />
                <b-avatar size="114" :variant="randomVariant(1, 6)" :text="'Zt'" v-else />
              </div>
            </div>
            <h3 class="mt-2 mb-2 text-center text-common-color" v-if="projectFeedback.type === 'general'">
              {{ projectDetail && projectDetail.name }}
            </h3>
            <h3 class="mt-2 mb-2 text-center text-common-color" v-else>
              {{ feedbackFormData.feedback_receiver_id
              ? feedbackReceiver.full_name : 'Zignuts Technolab' }}
            </h3>
            <hr class="mb-2">
            <div class="d-flex justify-content-between align-items-center ">
              <div>
                <h6 class="text-muted font-weight-bolder">
                  We appreciate your time to submit your valuable feedback for {{ feedbackFormData.feedback_receiver_id
                  ? feedbackReceiver.full_name : 'Zignuts Technolab'}}.
                </h6>
              </div>
            </div>
          </b-col>
          <!-- user information card end-->
          <!--form start-->
          <b-col cols="12" md="8" lg="9">
            <div class="d-flex justify-content-center card-height">
              <b-col cols="12">
                <validation-observer ref="feedbackObserverRules">
                  <div>
                    <div class="feedback-header mt-3 ">
                      <h3 class="text-primary">Feedback Form</h3>
                    </div>
                    <div class="decribe-feedback test-max">

                      <b-form>
                        <div v-for="(question, index) in questions" :key="index">
                          <div class="d-flex align-items-baseline">
                            <p class="question-class">
                              {{ index + 1 }} {{ ". " + question.question }}
                            </p>
                            <p class="align-self-baseline ml-auto">
                              <validation-provider #default="{ errors }" :name="'Rating for question-' + (index + 1)"
                                rules="required">
                                <b-form-rating v-model="question.rating" :variant="errors.length ? 'danger' : 'warning'"
                                  inline :class="{ 'vibrate': errors.length }" no-border class="large-rating" />
                              </validation-provider>
                            </p>
                          </div>
                          <div class="font-weight-bold">
                            {{ question.description ? '(' + question.description + ')' :null }}
                          </div>

                        </div>
                        <b-form-group>
                          <!-- <span class="text-danger">*</span> -->
                          <label label-for="feedback" class="mt-1 question-class">Feedback (Optional) </label>
                          <validation-provider #default="{ errors }" name="feedback" rules="">
                            <b-form-textarea id="feedback" v-model="description" type="textarea" rows="4"
                              :class="{ 'red-border vibrate': errors.length }" name="feedback"
                              placeholder="Kindly give your feedback..." />
                          </validation-provider>
                        </b-form-group>
                      </b-form>
                    </div>

                    <!--submit btn start-->
                    <div class="submit d-flex justify-content-end">
                      <button class="commonBtn mt-2" :disabled="loader" @click="validationForm()">
                        Submit Feedback
                      </button>
                    </div>
                    <!--submit btn end-->
                  </div>
                </validation-observer>
              </b-col>
            </div>
          </b-col>
          <!--form end-->
        </b-row>
      </b-card>
    </div>
    <FeedbackFormStatusCard :status="feedbackFormStatus" v-if="feedbackFormStatus" />

  </div>
</template>

<script>
import FeedbackFormStatusCard from './FeedbackFormStatusCard.vue'
import { BRow, BCol, BFormGroup, BForm } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { feedbackFormDetail } from "../../../mixin/peerFeedback/form";

export default {
  name: "FeedBackTable",
  mixins: [feedbackFormDetail],
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    ValidationObserver,
    ValidationProvider,
    FeedbackFormStatusCard
  },
};
</script>
<style lang="scss">
@import "../../../assets/scss/component-css/feedback.css";
@import "../../../assets/scss/component-css/feedback-form.scss";
</style>
