<template>
  <div class="report-view report-master">
    <div class="viewEdit ttrInfo">
      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0">
          <p class="task-timesheet">
            Report Master
          </p>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <div class="report-task-section">
        <b-row>
          <b-col>
            <b-card
              class="report-master-card"
              no-body
            >
              <h4>Project & Task Report</h4>
              <div class="report-master-content">
                <ul>
                  <li>
                    <router-link :to="{ name: 'taskTimeSheetReport' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Task Timesheet Report
                    </router-link>
                  </li>
                  <li v-if="userInfo && userInfo.role == 'SA'">
                    <router-link :to="{ name: 'summary-report' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Task Summary Report
                    </router-link>
                  </li><li v-if="userInfo && (userInfo.role == 'SA' || userInfo.is_resource_managing)">
                    <router-link :to="{ name: 'projectReports' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Project Health Report
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="userInfo.users && userInfo.role == 'SA' || userInfo.is_resource_managing == true">
            <b-card
              class="report-master-card"
              no-body
            >
              <h4>Skill Report</h4>
              <div class="report-master-content">
                <ul>
                  <li>
                    <router-link :to="{ name: 'skillReports' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Skill Report
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="userInfo && userInfo.role == 'SA'">
            <b-card
              class="report-master-card"
              no-body
            >
              <h4>Fail Sync History Report</h4>
              <div class="report-master-content">
                <ul>
                  <li>
                    <router-link :to="{ name: 'syncReports' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Fail Sync History Report
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="userInfo && (userInfo.role == 'SA' || userInfo.is_resource_managing == 1)">
            <b-card
              class="report-master-card"
              no-body
            >
              <h4>Resource Availability Report</h4>
              <div class="report-master-content">
                <ul>
                  <li>
                    <router-link :to="{ name: 'ResourceAvailabilityReport' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      > Resource Availability Report
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
          <b-col v-if="userInfo.users && userInfo.role == 'SA' || userInfo.is_resource_managing == true">
            <b-card
              class="report-master-card"
              no-body
            >
              <h4>Defects & PR Request Report</h4>
              <div class="report-master-content">
                <ul>
                  <li>
                    <router-link :to="{ name: 'bugReports' }">
                      <img
                        :src="Image"
                        alt="arrow-img"
                      >Defects Report
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="{ name: 'prRequestReports' }">
                      <img 
                        :src="Image"
                        alt="arrow-img"
                      >PR Request Report
                    </router-link>
                  </li>
                </ul>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// import Image from '../../assets/images/icons/chevrons-left.svg'
import Image from '../assets/images/icons/chevrons-left.svg'

export default {
  name: 'HeaderBlock',
  data() {
    return {
      Image,
    }
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/component-css/vieweditheader.scss";
@import "../assets/scss/component-css/approvals.css";
@import "../assets/scss/component-css/report.css";
.pagination {
  z-index: 999;
}
.table-responsive {
  @media (max-width: 3000px) {
    display: inline-table;
  }
  @media (max-width: 1800px) {
    display: block;
  }
}
.new-time-report {
  .emp-name {
    display: block;
  }
}
.newproject-card {
  padding: 24px;
  border-radius: 6px;
}
.label-sweet {
  color: #5e5873 !important;
  font-size: 14px;
  margin-left: 10px;
}
.input-aleart {
  height: 20px;
  width: 20px;
  border-radius: 20px;
  accent-color: #fb5362;
  overflow-y: hidden;
}
.report-loader-screen {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
  z-index: 1080;
  img {
    width: 100px;
  }
  .overdlow-sheet {
    overflow-y: hidden;
  }
}
.task-timesheet {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
}
.custom-drop-report {
  &.side-bar-clear {
    padding: 10px 20px;
  }
  .dropdown-toggle {
    color: #f97753 !important;
  }
  .dropdown-item:hover,
  [dir] .dropdown-item:focus {
    background-color: rgba(249, 119, 83, 0.12) !important;
    color: #f97753;
  }

  .dropdown-menu {
    border-radius: 0.358rem;
    transform: scale(1, 0);
    box-shadow: 0 5px 25px rgb(34 41 47 / 10%) !important;
    background-color: #fff !important;
    left: 0px !important;
  }
}
</style>
