<template>
  <div class="">
    <div class="viewEdit ttrInfo">

      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <div class="calendar justify-content-between mt-0 custom-width">
          <p class="task-timesheet">
            Defects Report
          </p>
          <!-- Previous Arrow, Calander, Next Arrow -->
          <div class="calendar mt-0 justify-content-center align-items-center calendarBg">
            <div id="date-select" class="date-select mr-0">
              <div class="date-dropdown">
                <VueDatePicker v-model="startDate" type="quarter" no-header />
              </div>
            </div>
            <Help v-show="$store.state.app.userManualList" code="SR" />
          </div>
        </div>
      </b-card>
      <!-- end date picker toggle -->

      <!-- start Filters & export -->
      <div class="card-filter-section">
        <div class="d-flex align-items-center flex-wrap justify-content-between">
          <div class="d-flex align-items-center justify-content-start flex-wrap">
            <p class="filter-title">
              Filters:
            </p>
            <div>
              <custom-dropdown v-model="manage_by" :options="manage_by_users" label="Managed by" icon="EditIcon" />
            </div>
            <div>
              <custom-dropdown v-model="reporting_to" :options="reporting_to_users" label="Reporting to"
                icon="EditIcon" />
            </div>
            <option-drop-down v-model="perPage" />
          </div>
          <div class="d-flex align-items-center">
            <div class="mr-1">
              <b-input-group class="input-group-merge search-project"
                style="min-width: 250px; max-width: 250px; width: 250px">
                <b-form-input v-model="debouncedSearch" placeholder="Search by Employee Name" />
                <b-input-group-append is-text>
                  <feather-icon v-if="debouncedSearch" icon="XIcon" @click="debouncedSearch = null, search = null" />
                  <feather-icon icon="SearchIcon" />
                </b-input-group-append>
              </b-input-group>
            </div>
            <div class="">
              <button class="apply-filter filterRedesign mr-1" @click="bugReport()">
                Apply Filter
              </button>
              <button class="apply-filter-clear filterRedesign" @click="clearFilter()">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="table role">
        <!-- Table start from here -->
        <table-loader v-if="loader" :fields="fields" />
        <b-row v-else>
          <b-col cols="12">
            <!-- data table start-->
            <b-table responsive :items="items" :fields="fields" show-empty
              class="scrollable-element tableDesigns mastertblColor non-project-table">
              <template #cell(manage_by)="data">
                <div class="d-flex align-items-center">
                  <p >
                    {{
                      data.item.manage_by_user && data.item.manage_by_user.full_name ? data.item.manage_by_user.full_name : '-'
                    }}
                  </p>

                </div>
              </template>
              <template #cell(reporting_to)="data">
                <div class="d-flex align-items-center">
                  <p >
                    {{ data.item.users && data.item.users.full_name ? data.item.users.full_name : '-' }}
                  </p>
                </div>
              </template>
            </b-table>

            <!-- data table complete -->
            <img v-if="totalCount == 0" src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img">
          </b-col>
        </b-row>
        <!-- pagination section -->
        <Pagination v-if="totalCount > 0 && !loader" :current-page="currentPage" :per-page="perPage"
          :total-count="totalCount" @currentPage="currentPage = $event" />
        <!-- pagination section complete here -->
      </div>

    </div>
  </div>
</template>

<script>
import {
  VBToggle, BTable,
} from 'bootstrap-vue'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import { bugReportDetail } from "../../mixin/report/bug/index"  

export default {
  name: 'HeaderBlock',
  mixins: [bugReportDetail],
  components: {
    customDropdown,
    VueDatePicker,
    BTable,
  },
  directives: {
    'b-toggle': VBToggle,
  },
}
</script>
