import Vue from "vue";
import axios from "axios";
import CryptoJS from "crypto-js";

const baseURL = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_VERSION}`;

const axiosIns = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Utility functions for encryption and decryption
const getEncryptionKeys = () => {
  const Encrypt_key = process.env.VUE_APP_ENCRYPT_KEY;
  const Encrypt_iv = process.env.VUE_APP_ENCRYPT_IV;
  return {
    Encrypt_key: CryptoJS.enc.Utf8.parse(Encrypt_key),
    Encrypt_iv: CryptoJS.enc.Utf8.parse(Encrypt_iv),
  };
};

const encryptData = (data) => {
  const { Encrypt_key, Encrypt_iv } = getEncryptionKeys();
  const jsonData = JSON.stringify(data);
  return CryptoJS.AES.encrypt(jsonData, Encrypt_key, {
    iv: Encrypt_iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
};

const decryptData = (encrypted) => {
  const { Encrypt_key, Encrypt_iv } = getEncryptionKeys();
  const decrypted = CryptoJS.AES.decrypt(encrypted, Encrypt_key, {
    iv: Encrypt_iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

// Request transformer
axiosIns.interceptors.request.use((config) => {
  if (process.env.VUE_APP_ENV == "local") {
    return config;
  }
  if (config.data) {
    config.data.payload = encryptData(config.data);
  }
  if (config.data && config.data._method) {
    config.data = {
      _method: config.data._method,
      payload: encryptData(config.data),
    };
  } else {
    config.data = { payload: encryptData(config.data) };
  }

  return config;
});

// Response interceptor
axiosIns.interceptors.response.use(async (response) => {
  try {
    const rawData = response.data;

    if (process.env.VUE_APP_ENV == "local") {
      return response.data;
    }

    // If the response type is 'blob', return the response directly
    if (response.config.responseType === "blob") {
      return response;
    }
    if (!rawData || typeof rawData !== "string" || rawData.trim() === "") {
      return {}; // Return an empty object if there's no valid data
    }

    const decryptedText = decryptData(rawData);

    if (!decryptedText) {
      throw new Error("Decryption returned an empty string");
    }

    return JSON.parse(decryptedText); // Return the parsed data
  } catch (error) {
    return {}; // Return a default object in case of error
  }
});

Vue.prototype.$http = axiosIns;

export default axiosIns;
