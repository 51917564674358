<template>
  <div class="clock-in-out">
    <div class="viewEdit">
      <!-- Resource managemane lable & year-->
      <div class="card-filter-section">
        <div class="d-flex align-items-center justify-content-between flex-wrap">
          <div>
            <p class="filter-title font-weight-bold">
              Resource Management (Planned)
            </p>
          </div>
          <div class="calendar mt-0 justify-content-center align-items-center calendarBg">
            <div id="date-select" class="date-select mr-0">
              <div class="date-dropdown">
                <VueDatePicker v-model="date" :type="tab == 'weekly' ? 'quarter' : 'month'" no-header />
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <!-- view -->
            <div class="card-header-edit">
              <div class="card-header-first mt-0">
                <div class="button-group resource-plan mr-1">
                  <b-button-group>
                    <b-button :class="[tab == 'daily' ? 'btn-role active' : 'btn-role']" variant="secondary"
                      @click="tab = 'daily'">
                      <feather-icon icon="CalendarIcon" class="calendar-icon" size="15" />
                      Monthly</b-button>
                    <b-button :class="[
                      tab == 'weekly' ? 'btn-role active' : 'btn-role',
                    ]" variant="secondary" @click="tab = 'weekly'"><img :src="Image">Quarterly
                    </b-button>
                  </b-button-group>
                  <Help v-show="$store.state.app.userManualList" code="Planned" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--filter start-->
      <div class="card-filter-section">
        <b-row>
          <b-col cols="11" lg="11" class="d-block d-lg-flex align-items-center justify-content-start flex-wrap">
            <p class="filter-title">
              Filters:
            </p>
            <div class="my-50">
              <custom-dropdown v-model="is_active" :options="isActiveList" :search-box="false" :hide-close="true"
                label="Active" icon="EditIcon" />
            </div>

            <div class="w-lg-100 my-50">
              <custom-dropdown v-model="searchUser" :options="filterUserOptions" multiple label="Members"
                icon="EditIcon" @search="updateSearch" />
            </div>

            <div v-if="userInfo && userInfo.role == 'SA'" class="w-lg-100 my-50">
              <custom-dropdown v-model="manage_by" :options="manage_by_users" label="Managed by" icon="EditIcon" />
            </div>
            <div class="w-lg-100 my-50">
              <custom-dropdown v-model="reporting_to" :options="reporting_to_users" label="Report To"
                class="resource-plan" icon="EditIcon" />
            </div>
            <div v-if="userInfo.role == 'SA'" class="my-50">
              <custom-dropdown v-model="group_id" :options="groups" label="Group" icon="EditIcon" />
            </div>
            <div class="w-lg-100 my-50">
              <custom-dropdown v-model="skills" :options="userSkills" multiple label="Skills" icon="EditIcon" />
            </div>
            <div v-if="tab == 'daily'" class="w-lg-100 my-50">
              <custom-dropdown v-model="allocation" :options="allocations" :search-box="false" label="Allocation"
                icon="EditIcon" />
            </div>
            <div class="w-lg-100 mr-1 my-50">
              <option-drop-down v-model="perPage" />
            </div>
            <div class="my-50">
              <button class="apply-filter mr-1 filterRedesign" @click="applyFilter()">
                Apply Filter
              </button>
              <button class="apply-filter-clear filterRedesign" @click="clearFilter()">
                Clear
              </button>
            </div>
          </b-col>
          <b-col class="d-flex align-items-center justify-content-end" lg="1">
            <b-button v-b-toggle.addResourceTeamMember class="btn-role btn-secondary" variant="secondary">
              <feather-icon icon="PlusIcon" class="calendar-icon" size="15" />
              Add</b-button>
          </b-col>
        </b-row>
      </div>
      <!--filter end-->

      <!-- Monthly -->
      <div v-if="tab == 'daily' && !loader">
        <div class="report-view skill-report actual-report-table">
          <table-loader v-if="resourceLoader" :fields="monthly" />
          <b-table v-else :fields="monthly" :items="Object.keys(monthlyItems)"
            class="resource-user-table d-inline-block">
            <template #cell(name)="data">
              <div class="resource-user-data">
                <div :class="monthlyItems[data.item][0].totalHours < 40
                    ? 'available availibility'
                    : monthlyItems[data.item][0].totalHours > 119
                      ? 'not-available availibility '
                      : 'part-available availibility'
                  " />

                <div class="resource-user">
                  <div class="d-flex align-items-center">
                    <b-avatar :src="monthlyItems[data.item] &&
                        monthlyItems[data.item].length
                        ? monthlyItems[data.item][0].picture
                        : ''
                      " :text="monthlyItems[data.item] &&
                          monthlyItems[data.item].length
                          ? monthlyItems[data.item][0].full_name
                          : '' | avatarText
                        " size="50" />
                  </div>
                  {{
                    monthlyItems[data.item] && monthlyItems[data.item].length
                      ? monthlyItems[data.item][0].full_name
                      : ""
                  }}
                </div>
              </div>
              <div class="users-tag">
                <span v-for="(primary, index) in monthlyItems[data.item][0]
                  .primary_skills" v-if="
                      monthlyItems &&
                      monthlyItems[data.item] &&
                      monthlyItems[data.item].length &&
                      monthlyItems[data.item][0] &&
                      monthlyItems[data.item][0].primary_skills &&
                      index < 1
                    " :key="index">{{ primary.name }}</span>

                <span v-if="
                  monthlyItems &&
                  monthlyItems[data.item] &&
                  monthlyItems[data.item].length &&
                  monthlyItems[data.item][0] &&
                  monthlyItems[data.item][0].primary_skills &&
                  monthlyItems[data.item][0].primary_skills.length > 1
                " v-b-tooltip.hover.top="skillsFunction(monthlyItems[data.item][0].primary_skills)
                    ">+
                  {{
                    monthlyItems[data.item][0].primary_skills.length - 1
                  }}</span>
              </div>
            </template>

            <template #cell(Projects)="data">
              <div v-b-modal.resource-modal @click="
                (popIndex = data.item), (modalDate = date), modalPlanShow()
                ">
                <div v-for="(resourcePlan, index) in monthlyItems[data.item][0]
                  .resource_plan" v-if="
                      monthlyItems[data.item][0] &&
                      monthlyItems[data.item][0].resource_plan &&
                      monthlyItems[data.item][0].resource_plan.length
                    " :key="index" class="resource-user-project">
                  <div class="resource-project-name">
                    {{ resourcePlan.project.code }}
                  </div>
                  <div class="resource-project-hour">
                    <span>{{
                      resourcePlan.planned_hours > 0
                        ? ((resourcePlan.planned_hours * 100) / 160).toFixed(
                          2
                        )
                        : 0
                    }}%</span>
                    ({{
                      resourcePlan.planned_hours > 0
                        ? resourcePlan.planned_hours.toFixed(2)
                        : 0
                    }})
                  </div>
                </div>
              </div>
            </template>

            <template #cell(total_hours)="data">
              <div class="resource-user-total">
                <span :class="monthlyItems[data.item][0].totalHours < 40
                    ? 'text-success'
                    : monthlyItems[data.item][0].totalHours > 119
                      ? 'text-danger'
                      : 'text-warning'
                  ">
                  {{
                    monthlyItems[data.item][0] &&
                      monthlyItems[data.item][0].totalHours
                      ? monthlyItems[data.item][0].percentage
                      : 0
                  }}
                  %
                </span>
                ({{
                  monthlyItems[data.item][0] &&
                    monthlyItems[data.item][0].totalHours
                    ? monthlyItems[data.item][0].totalHours
                    : 0
                }}
                hrs)
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <!-- Quatorly -->
      <div v-if="tab == 'weekly' && !loader">
        <table-loader v-if="resourceLoader" :fields="quarter" />
        <div v-else class="report-view skill-report align-th-table">
          <b-table :fields="quarter" :items="quarterItems">
            <template #cell(name)="data">
              <div class="resource-user-data">
                <div class="resource-user">
                  <b-avatar :src="data.item && data.item.picture ? data.item.picture : ''
                    " :text="monthlyItems[data.item] && monthlyItems[data.item].length
                        ? monthlyItems[data.item][0].full_name
                        : '' | avatarText
                      " size="50" />

                  {{ data.item.full_name }}
                </div>
              </div>
              <div class="users-tag">
                <span v-for="(primary, index) in data.item.primary_skills" v-if="
                  data.item.primary_skills &&
                  data.item.primary_skills.length &&
                  index < 1
                " :key="index">{{ primary.name }}</span>
                <span v-if="
                  data.item.primary_skills &&
                  data.item.primary_skills.length &&
                  data.item.primary_skills.length > 1
                " v-b-tooltip.hover.top="skillsFunction(data.item.primary_skills)
                    ">
                  +{{ data.item.primary_skills.length - 1 }}</span>
              </div>
            </template>

            <template #cell(january)="data">
              <div v-b-modal.resource-modal :class="data.item.month[0] < 40
                  ? 'resource-user-total quarter-hour available'
                  : data.item.month[0] > 119
                    ? 'resource-user-total quarter-hour not-available'
                    : 'resource-user-total quarter-hour part-available'
                " @click="
                  (popIndex = data.item.id), filterMonth(0), modalPlanShow()
                  ">
                <!-- modalPlanShow() -->
                <span :class="data.item.month[0] < 40
                    ? 'text-success'
                    : data.item.month[0] > 119
                      ? 'text-danger'
                      : 'text-warning'
                  ">{{
                     ((data.item.month[0] / 160) * 100).toFixed(2)
                  }}%</span>
                ({{ data.item.month[0] }}hrs)
              </div>
            </template>

            <template #cell(feb)="data">
              <div v-b-modal.resource-modal :class="data.item.month[1] < 40
                  ? 'resource-user-total quarter-hour available'
                  : data.item.month[1] > 119
                    ? 'resource-user-total quarter-hour not-available'
                    : 'resource-user-total quarter-hour part-available'
                " @click="
                  (popIndex = data.item.id), filterMonth(1), modalPlanShow()
                  ">
                <span :class="data.item.month[1] < 40
                  ? 'text-success'
                  : data.item.month[1] > 119
                    ? 'text-danger'
                    : 'text-warning'
                  ">{{
                    ((data.item.month[1] / 160) * 100).toFixed(2)
                  }}%</span>
                ({{ data.item.month[1] }}hrs)
              </div>
            </template>

            <template #cell(march)="data">
              <div v-b-modal.resource-modal :class="data.item.month[2] < 40
                  ? 'resource-user-total quarter-hour available'
                  : data.item.month[2] > 119
                    ? 'resource-user-total quarter-hour not-available'
                    : 'resource-user-total quarter-hour part-available'
                " @click="
                  (popIndex = data.item.id), filterMonth(2), modalPlanShow()
                  ">
                <span :class="data.item.month[2] < 40
                  ? 'text-success'
                  : data.item.month[2] > 119
                    ? 'text-danger'
                    : 'text-warning'
                  ">{{
                    ((data.item.month[2] / 160) * 100).toFixed(2)
                  }}%</span>
                ({{ data.item.month[2] }}hrs)
              </div>
            </template>
          </b-table>
        </div>
      </div>

      <!--no data found Image -->
      <img v-if="totalCount == 0 && !resourceLoader" src="@/assets/images/nodatafound/nodata.svg" alt="no-data"
        class="no-data-img">
      <div class="pb-1">
        <!-- pagination section -->
        <Pagination v-if="totalCount > 0 && !resourceLoader" :current-page="currentPage" :per-page="perPage"
          :total-count="totalCount" @currentPage="currentPage = $event" />
      </div>
      <!-- pagination section complete here -->

      <b-modal v-if="popIndex" id="resource-modal" class="resource-modal" size="lg" centered>
        <div class="resource-modal-content">
          <div class="resource-profile viewEdit">
            <div class="resource-user-detail">
              <img v-if="userData && userData.picture" :src="userData && userData.picture ? userData.picture : ''">

              <b-avatar v-else :text="userData && userData.full_name
                  ? userData.full_name
                  : '' | avatarText
                " size="50" />

              <span v-if="userData && userData.name"> {{ userData.name }}</span>
              <span v-if="userData && userData.last_name">
                {{ userData.last_name }}</span>
            </div>

            <div v-if="userData && userData.primary_skills" class="resource-user-tags">
              <span v-for="(primary, index) in userData.primary_skills" :key="index">{{ primary.name }}</span>
            </div>
            <div class="devider" />
            <div class="calendar mt-0 justify-content-center calendarBg">
              <div id="date-select" class="date-select">
                <VueDatePicker v-model="modalDate" type="month" no-header />
              </div>
            </div>
          </div>
          <b-table :fields="modal" :items="userResourcePlanData" class="modal-table approvel-table">
            <!-- v-else -->
            <!-- A custom formatted data column cell -->
            <template #cell(name)="data">
              <div class="resource-user-data">
                <div class="available availibility" />
                <div class="resource-user">
                  {{ data.item.project.name }}
                  <span>({{ data.item.project.code }})</span>
                </div>
              </div>
            </template>

            <template #cell(allocation)="data">
              <div class="resource-user-total quarter-hour available">
                <span>{{
                  data.item.planned_hours
                    ? ((data.item.planned_hours * 100) / 160).toFixed(2)
                    : 0
                }}
                  / 100 %</span>
              </div>
            </template>

            <template #cell(assignment)="data">
              <div class="resource-user-total quarter-hour part-available">
                {{ data.item.planned_hours }} / 160
                <div class="action-icons">
                  <div class="text-primary" @click="edit(data.item)">
                    <feather-icon icon="EditIcon" size="18" />
                  </div>
                  <div class="text-secondary" @click="deleteConfirmation(data.item.id)">
                    <feather-icon icon="Trash2Icon" size="18" />
                  </div>
                </div>
              </div>
            </template>
          </b-table>

          <p v-if="userResourcePlanData && !userResourcePlanData.length">
            No data found
          </p>
        </div>
      </b-modal>
    </div>

    <AddMember ref="addEditMember" :teams="this.users && this.users.length ? this.users : []" :create-type="'plan'"
      @plannedUserList="
        tab != 'weekly' ? monthlyPlanList() : quarterlyPlanList()
        " />
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import * as moment from 'moment/moment'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import Image from '../../assets/images/icons/pie.svg'
import User from '../../assets/images/avatars/1-small.png'
import AddMember from '../project/projectsummary/AddTeamMember&ResourcePlan.vue'

const date = new Date()

export default {
  name: 'ViewEditHeader',
  components: {
    TableButton,
    customDropdown,
    AddMember,
    VueDatePicker,
  },
  directives: {
    'b-toggle': VBToggle,
  },

  data() {
    return {
      User,
      popIndex: null,
      userResourcePlanData: [],
      Image,
      loader: false,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      tab: 'daily',
      daily: true,
      weekly: false,
      users: [],
      manage_by_users: [],
      manage_by: null,
      user_id: null,
      weekDate: null,
      searchUser: [],
      year: null,
      years: [],
      searchManageByUser: null,
      resource_plans: [],
      usersIds: [],
      userSkills: [],
      skills: [],
      group_id: null,
      groups: [],
      rangeDate: null,
      startDate: null,
      endDate: null,
      reporting_to_users: [],
      reporting_to: null,
      lastTabstartDate: null,
      lastTabendDate: null,
      resourceLoader: false,
      userData: null,
      is_active: 'active',
      // filterMonth: null,
      modalLoader: true,
      modalDate: date,
      date: `${date.getFullYear()}-2`,
      minDate: `${date.getFullYear() - 3}-2`,
      maxDate: `${date.getFullYear() + 3}-2`,
      monthly: [
        { key: 'name', label: 'Name' },
        { key: 'Projects', label: 'Projects' },
        { key: 'total_hours', label: 'Total Hours' },
      ],
      monthlyItems: [],
      quarter: [
        { key: 'name', label: 'Name' },
        { key: 'january', label: 'January' },
        { key: 'feb', label: 'February' },
        { key: 'march', label: 'March' },
      ],
      quarterItems: [],
      modal: [
        { key: 'name', label: 'Project Name' },
        { key: 'allocation', label: 'Allocation' },
        { key: 'assignment', label: 'Assignment' },
      ],
      modalItems: [],
      allocations: [
        {
          value: 'available',
          label: 'Available',
        },
        {
          value: 'parttime',
          label: 'Part Time',
        },
        {
          value: 'fulltime',
          label: 'Full Time',
        },
      ],
      allocation: null,
      userSearch: null,
    }
  },

  computed: {
    filterUserOptions() {
      return this.filterUserOptionsFunction(this.users, this.userSearch)
    },
  },
  watch: {
    tab() {
      const start = this.startDate
      const end = this.endDate
      this.startDate = this.lastTabstartDate
        ? this.lastTabstartDate
        : this.startDate
      this.endDate = this.lastTabendDate ? this.lastTabstartDate : this.endDate
      this.lastTabstartDate = start
      this.lastTabendDate = end
      this.totalCount = 0
      if (this.currentPage !== 1) {
        this.currentPage = 1
      }
      this.currentMonth()
    },
    date() {
      this.applyFilter()
    },
    modalDate() {
      this.modalPlanShow()
    },
    currentPage: {
      handler(value) {
        if (value) {
          this.applyFilter()
        }
      },
    },
    perPage() {
      if (this.currentPage === 1) {
        this.applyFilter()
      } else {
        this.currentPage = 1
      }
    },
  },
  mounted() {
    /* getting start & end date */
    this.currentMonth()
    // this.monthlyPlanList();
    this.filterFunctionCallFromMixin()
  },
  methods: {
    updateSearch(data) {
      this.userSearch = data
    },
    filterMonth(nv) {
      const month = moment(this.date, 'YYYY-M').format('M')
      const months = this.getQuarterMonth(month)
      this.modalDate = moment(
        `${moment(this.date, 'YYYY-M').format('YYYY')}-${months[nv]}`,
        'YYYY-MM',
      ).format('YYYY-MM')
    },
    /*  filter data */
    async filterFunctionCallFromMixin() {
      /**  role super admin grouplist & manageby list , reporting , primaryskill , userlist  common api calling  */
      if (this.userInfo && this.userInfo.role === 'SA') {
        if (this.$store.state.app.groupList.length === 0) {
          this.groups = await this.getGroupData()
        } else {
          this.groups = this.$store.state.app.groupList
        }

        if (this.$store.state.app.manageByUsersList.length === 0) {
          this.manage_by_users = await this.manageByUserList()
        } else {
          this.manage_by_users = this.$store.state.app.manageByUsersList
        }
      } else {
        this.searchManageByUser = this.userInfo.id
      }

      if (this.$store.state.app.reportingUserList.length === 0) {
        this.reporting_to_users = await this.reportingtoUserList()
      } else {
        this.reporting_to_users = this.$store.state.app.reportingUserList
      }

      if (this.$store.state.app.userSkills.length === 0) {
        this.userSkills = await this.getPrimarySkillData()
      } else {
        this.userSkills = this.$store.state.app.userSkills
      }

      if (this.$store.state.app.usersList.length === 0) {
        this.users = await this.userList()
      } else {
        this.users = this.$store.state.app.usersList
      }

      if (this.userInfo && this.userInfo.role === 'EMP') {
        this.users = this.$store.state.app.usersList.filter(
          element => element.manage_by === this.userInfo.id,
        )
      }
    },
    /* filters */
    applyFilter() {
      if (this.tab === 'daily') {
        this.monthlyPlanList()
      } else {
        this.quarterlyPlanList()
      }
    },
    /*  get start & end date  */
    currentMonth() {
      this.startDate = moment().startOf('month').format('YYYY-MM-DD')
      this.endDate = moment().endOf('month').format('YYYY-MM-DD')
      this.date = this.tab === 'weekly'
        ? `${moment().endOf('month').format('YYYY')}-${moment().quarter()}`
        : moment().endOf('month').format('YYYY-MM')
    },
    /*  resource plans */
    async monthlyPlanList() {
      this.resourceLoader = true

      const input = {
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        member:
          this.searchUser && this.searchUser.length
            ? this.searchUser
            : this.group_id
              ? ''
              : this.usersIds,
        page: this.currentPage,
        per_page: this.perPage,
        primary_skill_ids: this.skills,
        group_id: this.group_id,
        month: [moment(this.date, 'YYYY-MM').format('MM')],
        year: moment(this.date, 'YYYY-MM').format('YYYY'),
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
        allocation: this.allocation,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/user-plan-list',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.resource_plan
        this.totalCount = response.data.count

        if (this.tab == 'daily') {
          this.monthlyItems = data.reduce((acc, item) => {
            if (!acc[item.id]) {
              acc[item.id] = []
            }
            acc[item.id].push(item)
            return acc
          }, {})

          /*  to get total hours & percentage */
          Object.keys(this.monthlyItems).forEach(element => {
            let total = 0

            if (
              this.monthlyItems[element][0].resource_plan
              && this.monthlyItems[element][0].resource_plan.length
            ) {
              this.monthlyItems[element][0].resource_plan.forEach(element => {
                total += element.planned_hours ? element.planned_hours : 0
              })
            }

            this.monthlyItems[element][0].totalHours = total
            this.monthlyItems[element][0].percentage = total == 0
              ? 0
              : parseFloat(((total * 100) / 160).toFixed(2)).toString()
          })
        }
        this.resourceLoader = false
      } else {
        this.resourceLoader = false
      }
    },
    /*  resource plans */
    async quarterlyPlanList() {
      /* for get quatorly months */
      const month = moment(this.date, 'YYYY-M').format('M')
      const months = this.getQuarterMonth(month)
      /*  table Column name update */
      this.quarter = this.generateMonthLabels(months)
      this.resourceLoader = true

      const input = {
        member:
          this.searchUser && this.searchUser.length
            ? this.searchUser
            : this.group_id
              ? ''
              : [],
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        page: this.currentPage,
        per_page: this.perPage,
        primary_skill_ids: this.skills,
        group_id: this.group_id,
        month: months,
        year: moment(this.date, 'YYYY-M').format('YYYY'),
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/plan-quarterly-list',
        input,
        false,
      )
      if (response && response.data) {
        const data = response.data.resource_plan
        this.totalCount = response.data.count

        this.quarterItems = data

        /*  to get total hours & percentage */
        Object.keys(data).forEach((element, index) => {
          this.quarterItems[element].month = []
          // let months = months;
          months.forEach(e => {
            const m = this.quarterItems[element].resource_plan
              && this.quarterItems[element].resource_plan.length
              ? this.quarterItems[element].resource_plan.filter(
                c => moment(c.month).format('MM') === moment(e).format('MM'),
              )
              : []
            let monthHours = 0
            if (m && m.length) {
              m.forEach(element => {
                monthHours += element.planned_hours
              })
            }
            this.quarterItems[element].month.push(monthHours)
          })
        })
        this.resourceLoader = false
      } else {
        this.resourceLoader = false
      }
    },
    /*  resource plans */
    async modalPlanShow() {
      this.modalLoader = true
      this.userResourcePlanData = []
      const id = this.tab != 'daily'
        ? this.popIndex
        : this.monthlyItems[this.popIndex][0].id

      const input = {
        member: [id],
        month: [moment(this.modalDate, 'YYYY-MM').format('MM')],
        year: moment(this.modalDate, 'YYYY-MM').format('YYYY'),
      }

      const response = await this.getHTTPPostResponse(
        'admin/master/resource-plan/user-plan-list',
        input,
        false,
      )
      if (response && response.data) {
        let data = response.data.resource_plan
        data = data.reduce((acc, item) => {
          if (!acc[item.id]) {
            acc[item.id] = []
          }
          acc[item.id].push(item)
          return acc
        }, {})

        /** Resource plan */
        Object.keys(data).forEach(element => {
          this.userData = data && data[element] && data[element][0]
          this.userResourcePlanData = data[element][0].resource_plan
        })

        this.modalLoader = false
      } else {
        this.modalLoader = false
      }
    },
    clearFilter() {
      this.group_id = null
      this.skills = []
      this.searchUser = []
      this.reporting_to = null
      this.manage_by = null
      this.allocation = null
      this.currentPage = 1
      this.perPage = 100
      this.allocation = null
      this.is_active = 'active'
      this.currentMonth()
      this.applyFilter()
    },

    /**
     * edit plan
     */
    edit(data) {
      this.$bvModal.hide('resource-modal')
      data.project_id = data.project
      eventBus.$emit('edit-plannedHours', data)
      this.$root.$emit('bv::toggle::collapse', 'addResourceTeamMember')
    },

    /**
     * delete plan confirmation
     */
    async deleteConfirmation(id) {
      const value = await this.conformationAlert(true)
      if (value.isConfirmed === true) {
        this.deletePlan(id)
      }
    },

    /**
     * delete Plan
     */
    async deletePlan(id) {
      const response = await this.getHTTPDeleteResponse(
        `admin/master/resource-plan/delete/${id}`,
        {},
        true,
      )
      if (response && response.status === 200) {
        this.modalPlanShow()
        this.applyFilter()
      }
    },

  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.scss";

.resource-plan {
  .radio-dropdown {
    left: -29px;
  }
}

.actual-report-table {
  th {
    &:nth-child(2) {
      text-align: start;
    }
  }
}
</style>
