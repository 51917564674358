import * as moment from "moment/moment";
import { eventBus } from "@/main";

export const projectForm = {
  data() {
    return {
      task_number_preference: null,
      code: null,
      project_types: [],
      project_status: [],
      id: null,
      name: null,
      client_id: null,
      description: null,
      estimated_start_date: null,
      estimated_end_date: null,
      actual_start_date: null,
      actual_end_date: null,
      type: null,
      status: null,
      consider_project_rate: null,
      estimate_type: null,
      billable_rate: null,
      time_estimate: null,
      budget_estimated: null,
      is_billable: true,
      configEstimatedStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configEstimatedEndDate: {
        minDate: null,
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configActualStartDate: {
        minDate: null,
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      configActualEndDate: {
        minDate: new Date(),
        maxDate: null,
        dateFormat: "Y/m/d",
      },
      considerProjectRates: [
        { name: "Yes", value: true },
        { name: "No", value: false },
      ],
      estimateValues: [
        { name: "No", value: "no" },
        { name: "Time", value: "time" },
        { name: "Budget", value: "budget" },
      ],
      configAssignmentStartDate: {
        maxDate: null,
        dateFormat: "Y-m-d",
        mode: "range",
      },
      assignment: null,
      time_plan: null,
      assignment_start_date: null,
      projectColor: null,
      project: null,
      considerRateValue: null,
      btnLoader: false,
      workType: "productive",
      project_scope_id: [],
      projectScopeShow: false,
      scopeColor: null,
      projectScopeName: null,
      clientShow: false,
      clientName: null,
      country: null,
      countries: [],
      sidebarBody: null,
    };
  },

  computed: {
    clientListComputed() {
      return this.$store.state.app.clientList.filter(
        (client) => client.is_active === true
      );
    },
    projectScopes() {
      return this.$store.state.app.projectScope;
    },
  },
  watch: {
    status() {
      if (this.id === null) {
        if (this.status === "NS") {
          this.configEstimatedStartDate.minDate = new Date();
          this.configEstimatedEndDate.minDate = new Date();
        } else {
          this.configEstimatedStartDate.minDate = null;
          this.configEstimatedEndDate.minDate = null;
        }
      }
    },
  },
  mounted() {
    this.sidebarBody = document.querySelector(".b-sidebar-body");
    if (
      this.userInfo.role === "SA" ||
      this.userInfo.role === "MN" ||
      this.userInfo.is_resource_managing
    ) {
      this.getCountryData();
      this.clientList();
      this.getProjectScopes();
    }
    eventBus.$on("edit-project", (id) => {
      if (id) {
        this.getProjectDetails(id);
        this.getProjectTypeData();
        this.getProjectStatusData();
      }
    });
  },
  destroyed() {
    eventBus.$off("edit-project");
  },
  methods: {
    // Add the 'overflow-hidden' class if the sidebar exists
    addClass() {
      if (this.sidebarBody) {
        this.sidebarBody.classList.add("overflow-hidden");
      }
    },

    // Remove the 'overflow-hidden' class if the sidebar exists
    removeClass() {
      if (this.sidebarBody) {
        this.sidebarBody.classList.remove("overflow-hidden");
      }
    },

    removeBodyScroll() {
      document.body.classList.add("overflow-hidden");
    },
    /**
     * Minimum Date config EstimatedStartDate
     * @returns Estimate start date
     */
    onEstimatedStartDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedEndDate, "minDate", dateStr);
    },
    /**
     * Maximum Date EstimatedEndDate
     * @returns Estimate End date
     */
    onEstimatedEndDateChange(selectedDates, dateStr) {
      this.$set(this.configEstimatedStartDate, "maxDate", dateStr);
    },
    /**
     * Minimum Date ActualStartDate
     * @returns Actual start date
     */
    onActualStartDateChange(selectedDates, dateStr) {
      this.$set(this.configActualEndDate, "minDate", dateStr);
    },
    /**
     * Maximum Date ActualEndDate
     * @returns Actual end date
     */
    onActualEndDateChange(selectedDates, dateStr) {
      this.$set(this.configActualStartDate, "maxDate", dateStr);
    },
    /**
     * Get Countries data
     * @returns Countries (variable)
     */
    async getCountryData() {
      if (this.userInfo.role === "SA" || this.userInfo.is_resource_managing) {
        const input = {
          sort_field: "name",
          sort_order: "asc",
        };
        const response = await this.getHTTPPostResponse(
          "admin/master/client/countries-list",
          input,
          false
        );
        if (response && response.data) {
          const { data } = response;
          this.countries = data && data.countries ? data.countries : [];
        }
      }
    },

    /**
     * Add & Update the clientTableForm details & call client listing in clienttable
     * @emits clientList
     * @returns client detail add & update
     */
    async saveClientDtl() {
      if (!this.clientName && !this.country) {
        return;
      }
      const input = {
        name: this.clientName,
        country_id: this.country,
      };
      let response = null;

      response = await this.getHTTPPostResponse(
        "admin/master/client/create",
        input,
        true
      );

      if (response && response.status === 200) {
        this.clientList();
        this.clientShow = false;
        this.client_id = response.data.client.id;
        this.clientName = null;
        this.country = [];
      }
      this.btnLoader = false;
    },

    /**
     * Add & Update the projectScopeForm details & call projectScope listing in projectScopetable
     * @emits
     * @returns projectScope detail add & update
     */
    async saveProjectScopeDtl() {
      if (!this.scopeColor && !this.projectScopeName) {
        return;
      }
      const color = this.scopeColor.substring(1);
      const input = {
        name: this.projectScopeName,
        color,
      };
      let response = null;
      response = await this.getHTTPPostResponse(
        "admin/master/project-scope/create",
        input,
        true
      );

      if (response && response.status === 200) {
        this.getProjectScopes();
        this.projectScopeShow = false;
        this.project_scope_id = response.data.project_scope.id;
        this.projectScopeName = null;
        this.scopeColor = null;
      }
      this.btnLoader = false;
    },

    /**
     * Base function to fetch project-related data
     * @param {String} endpoint - API endpoint
     * @param {String} stateVariable - Local state variable to update
     * @param {String|null} storeMutation - Vuex mutation name (optional)
     */
    async fetchProjectData(endpoint, stateVariable, storeMutation = null) {
      const input = { is_active: 1 };
      const response = await this.getHTTPPostResponse(endpoint, input, false);

      if (response && response.data) {
        const { data } = response;
        this[stateVariable] = data.count ? data[stateVariable] : [];

        if (storeMutation) {
          this.$store.commit(storeMutation, this[stateVariable]);
        }
      }
    },

    /**
     * Get project scope list
     */
    async getProjectScopes() {
      await this.fetchProjectData(
        "admin/master/project-scope",
        "project_scopes",
        "app/UPDATE_PROJECT_SCOPE"
      );
    },

    /**
     * Get project type data
     */
    async getProjectTypeData() {
      await this.fetchProjectData("project/task", "project_types");
    },

    /**
     * Get project status data
     */
    async getProjectStatusData() {
      await this.fetchProjectData(
        "admin/master/project-status",
        "project_status"
      );
    },

    /*  set config of time plan & assignment base on date or other condition */
    setConfig(action) {
      /*   date seperate  */
      const dateArr = this.assignment_start_date.split(" to ");

      if (dateArr.length === 2) {
        const startDate = dateArr[0];
        const endDate = dateArr[1] ? dateArr[1] : dateArr[0];

        /*  set min & max date based on startDate */
        const minDate = moment(startDate).startOf("month").format("YYYY-MM-DD");
        const maxdate = moment(startDate).endOf("month").format("YYYY-MM-DD");
        let calcBusinessDays =
          1 + moment(endDate).diff(moment(startDate), "days"); // selected dates
        calcBusinessDays = calcBusinessDays > 30 ? 30 : calcBusinessDays;
        const monthdays =
          moment(startDate).endOf("month").format("MM") === "02"
            ? 1 + moment(maxdate).diff(moment(minDate), "days")
            : 30;

        switch (action) {
          case "config":
            this.$set(this.configAssignmentStartDate, "maxDate", maxdate);
            this.$set(this.configAssignmentStartDate, "minDate", minDate);

            /*  check assignment value issue */
            this.assignment = this.assignment ? this.assignment : "100";

            /*  set or update hours as per assignment  >> already value >> default set */
            this.time_plan = this.assignment
              ? (
                  ((this.assignment * 160) / (100 * monthdays)) *
                  calcBusinessDays
                ).toFixed(2)
              : this.time_plan
              ? this.time_plan
              : ((160 / (monthdays + 1)) * calcBusinessDays).toFixed(2);
            break;
          case "setassignment":
            this.assignment = (
              (this.time_plan * 100 * monthdays) /
              (160 * calcBusinessDays)
            ).toFixed(2);
            break;

          default:
            this.time_plan = (
              ((this.assignment * 160) / (100 * monthdays)) *
              calcBusinessDays
            ).toFixed(2);
        }
        this.time_plan = this.time_plan > 160 ? 160 : this.time_plan;
        this.assignment = this.assignment > 100 ? 100 : this.assignment;
      }
    },
    /**
     * Get project details from id
     * @returns Project Details
     */
    async getProjectDetails(id) {
      if(!id) return
      const response = await this.getHTTPPostResponse(
        `project/view/${id}`,
        {},
        false
      );
      if (response && response.data && response.data.project) {
        const data = response.data.project;
        this.id = data.id;
        this.code = data.code;
        this.task_number_preference = data.task_number_preference;
        this.name = data.name;
        this.client_id = data.client_id;
        this.description = data.description;
        this.estimated_start_date = data.estimated_start_date;
        this.estimated_end_date = data.estimated_end_date;
        this.actual_start_date = data.actual_start_date;
        this.actual_end_date = data.actual_end_date;
        this.type = data.type;
        this.projectColor = `#${data.color}`;
        this.considerRateValue = data.consider_project_rate ? "Yes" : "No";
        this.estimate_type = data.estimate_type;
        this.billable_rate = data.billable_rate;
        this.project = data.is_public === 1 ? "Public" : "Private";
        this.time_estimate = data.time_estimated;
        this.budget_estimated = data.budget_estimated;
        this.is_billable = !!data.is_billable;
        this.status = data.status;
        this.project_types = data.count ? data.project_types : [];
        this.workType = data.work_type;
        this.$root.$emit("bv::toggle::collapse", "addEditProject");
      }
    },
    /**
     * Validates the form and calls the specified save method if validation is successful.
     * @param {string} formRef - The reference name of the form to validate.
     * @param {string} saveMethod - The method to call if validation succeeds.
     * @param {boolean} [is_open=false] - Optional flag to determine the action ('open' or 'add').
     */
    validateForm(formRef, saveMethod, is_open = false) {
      this.$refs[formRef].validate().then((success) => {
        if (success) {
          this[saveMethod](is_open ? "open" : "add");
        }
      });
    },

    /**
     * Add & Update the project-form details & call project listing in project-table
     * @emits projectList
     * @returns project detail add & update
     */
    async saveProjectDtl(addOpen) {
      this.btnLoader = true;
      const color = this.projectColor.substring(1);
      const input = {
        name: this.name,
        client_id: this.client_id,
        description: this.description,
        estimated_start_date: this.estimated_start_date,
        estimated_end_date: this.estimated_end_date,
        actual_start_date: this.actual_start_date,
        actual_end_date: this.actual_end_date,
        type: this.type,
        time_estimated: this.time_estimate,
        budget_estimated: this.budget_estimated,
        status: this.status,
        code: this.code,
        color,
        work_type: this.workType,
        project_scope_id: this.project_scope_id,
      };

      let response = null;
      if (this.id) {
        response = await this.getHTTPPutResponse(
          `project/update/${this.id}`,
          input,
          true
        );
      } else {
        response = await this.getHTTPPostResponse(
          "project/create",
          input,
          true
        );
      }
      if (response && response.status === 200) {
        if (this.userInfo.role != "SA") {
          const dateArr = this.assignment_start_date.split(" to ");
          const input = {
            plan: [
              {
                project_id: response.data.project.id,
                user_id: this.userInfo.id,
                access_rights: "project_owner",
                createType: "project_user",
                time_plan: this.time_plan,
                assignment: this.assignment,
                expected_assignment_start_date: dateArr[0],
                expected_assignment_end_date: dateArr[1]
                  ? dateArr[1]
                  : moment(dateArr[0]).format("YYYY-MM-DD"),
                month: moment(dateArr[0]).format("MM"),
                year: moment(dateArr[0]).format("YYYY"),
                planned_hours: this.time_plan,
                is_billable: this.is_billable,
              },
            ],
          };
          await this.getHTTPPostResponse(
            "project-resource/create-plan-user",
            input
          );
        }
        if (addOpen === "add") {
          this.$root.$emit("bv::toggle::collapse", "addEditProject");
          this.$emit("projectList", true);
          setTimeout(() => {
            this.btnLoader = false;
          }, 200);
        } else {
          this.$router.push({
            name: "projectTask",
            params: {
              id: "summary",
              id2: response.data.project.name,
              id3: response.data.project.id,
            },
          });
        }
      }
      this.btnLoader = false;
    },
    /**
     * Clear form details
     */
    clearData() {
      this.id = null;
      this.name = null;
      this.name = null;
      this.code = null;
      this.color = null;
      this.task_number_preference = null;
      this.client_id = null;
      this.description = null;
      this.estimated_start_date = null;
      this.estimated_end_date = null;
      this.actual_start_date = null;
      this.actual_end_date = null;
      this.type = null;
      this.consider_project_rate = null;
      this.estimate_type = null;
      this.billable_rate = null;
      this.time_estimate = null;
      this.type = null;
      this.budget_estimated = null;
      this.considerRateValue = null;
      this.project = null;
      this.is_billable = true;
      this.projectColor = null;
      this.project_scope_id = [];
      this.clientName = null;
      this.country = null;
      this.scopeColor = null;
      this.projectScopeName = null;
      this.time_plan = null;
      this.assignment = null;
      this.assignment_start_date = null;
      this.status = null;
      this.$refs.projectObserver.reset();
    },

    /**
     * Clear form details and toggle sidebar
     */
    sidebarToggle() {
      this.clearData();
      this.workType = "productive";
      document.body.classList.remove("overflow-hidden");
    },
  },
};
